/* Conat Events Business HTML-5 Template */

/*** 

====================================================================
	Attachment Css Files
====================================================================

====================================================================
	Main Slider Section
====================================================================

***/
.img-banner-tablet, .img-banner-mobile{
	display: none;
}

.calendar1{
	display: none;
  }

.container-main-formulario{
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.formulario{
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-around;
	background-color: antiquewhite;
}
.lateral-right-form{
	width: 70%;
	background-color: steelblue;
	justify-self: end;
}
.interno{
	margin: 0 100px;
}
.interno img{
	margin: 0 100px;
}

.slider-one {
	position: relative;
	height: 100%;
}

.slider-one_slide {
	position: relative;
	overflow: hidden;
	background-size: cover;
	padding: 280px 0px 400px;
	background-size: cover;
	height: 1100px;
}
.band{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	background-color: #00aca1;
	padding: 10px 0px;
	
}
.band p{
	text-align: center;
	padding: 10px 15px;
	border-radius: 54px;
	margin: 0px 100px;
	color: #fff;
}
.btn-band, .btn-hubspot{
	padding: 15px 40px;
	border-radius: 54px;
	background: linear-gradient(70deg, #FFEC43 13.75%, rgba(255, 230, 4, 0.00) 181.64%), #FFF;
	box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 4px 0px rgba(45, 35, 66, 0.40), 0px 7px 12px -3px rgba(0, 0, 0, 0.25);
	font-weight: 700;
	font-size: 20px;
	cursor: pointer;
	color: #535353;
}
.btn-band:hover {
	border-radius: 54px;
	background: radial-gradient(100% 100% at 100% 0%, #712A7a 0%, #712A7E 100%);
	box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 4px 0px rgba(45, 35, 66, 0.40), 0px 7px 12px -3px rgba(0, 0, 0, 0.25);
	color: #fff;
  }

.btn-hubspot:hover{
	border-radius: 54px;
	background: radial-gradient(100% 100% at 100% 0%, #712A7a 0%, #712A7E 100%);
	box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 4px 0px rgba(45, 35, 66, 0.40), 0px 7px 12px -3px rgba(0, 0, 0, 0.25);
	color: #fff;
}
.btn-card-price{
	padding: 15px 40px;
	border-radius: 54px;
	background: linear-gradient(70deg, #FFEC43 13.75%, rgba(255, 230, 4, 0.00) 181.64%), #FFF;
	box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 4px 0px rgba(45, 35, 66, 0.40), 0px 7px 12px -3px rgba(0, 0, 0, 0.25);
	font-weight: 700;
	font-size: 20px;
	cursor: pointer;
	color: #535353;
}
.btn-card-price:hover{
	border-radius: 54px;
	background: radial-gradient(100% 100% at 100% 0%, #712A7a 0%, #712A7E 100%);
	box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 4px 0px rgba(45, 35, 66, 0.40), 0px 7px 12px -3px rgba(0, 0, 0, 0.25);
	color: #ffffff;
}
.hubspotContainer{
	display: flex;
	justify-content: center;
	z-index: 1;
	margin-top: -100px;
	
}
.hubspotContainer1 a{
	color: #fff;
	padding: 20px 70px;
}
.containerPromo{
	width: 80%;
	height: auto;
	margin: 20px auto;
	border-radius: 22px;
	background-size: cover;
	box-shadow: 0px 4px 4px 0px #00000040;
	background: linear-gradient(45deg, rgba(0,119,127,1) 4%, rgba(80,48,116,1) 25%, rgba(76,63,123,1) 83%, rgba(59,123,151,1) 98%);
}
.containerPromo1{
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 50px 0px 10px 0px;
}
.containerPromo1 .left{
	margin: 0px 100px;
}
.containerPromo1 .right{
	margin: 0px 100px;
	text-align: center;
}

.containerPromo1 .left span{
	position: absolute;
	top: 30px;
	left: 0;
	background-color: #f0f0f0;
	padding: 0px 20px;
	border-radius: 0px 30px 30px 0px;
	box-shadow: 0px 4px 4px 0px #00000040;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 14px;
	border: 1px solid #00000040;
	box-shadow: 0px 4px 4px 0px #00000040;
}
.containerPromo1 .left p{
	color: #fff;
	letter-spacing: 1.5px;
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
	text-align: center;
}

.containerPromo1 .right h5{
	color: #fff;
	font-weight: 700;
	font-size: 24px;
	text-align: center;
}
.containerPromo1 .right hr{
	border: none;
	color: #ffffff;
	height: 2px;
	margin: -2px 0px 30px 0px;
}
.containerPromo1 .right .btn-promo{
	margin: 0 auto;
}
.promo-slogan{
	display: flex;
	justify-content: center;
	margin: 30px 0px;
}
.promo-slogan hr{
	border: none;
	height: 2px;
	color: #fff;
	width: 45%;
	margin-right: 60px;
}
.promo-slogan p{
	line-height: 2;
	color: #fff;
	font-size: 11px;
	letter-spacing: 8px;
}
.container-calendar-wrap{
	margin: 0px auto;
	max-width: 1500px;
	width: 100%;
}
.container-calendar{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 100px auto 20px;
	width: 100%;
}
.container-calendar img{
	width: 100%;
	overflow: auto;
}
.container-calendar .right{
	align-self: flex-end;
	margin: 20px 0px;
}

.separated{
	margin: 0px 100px;
}
.centered {
    text-align: center;
    justify-content: center;
}

.container-video{
	position: relative;
	width: 100%;
	height: 100%;
}
.video-hero{
	width: 100%;
	height: 100%;
	object-fit: cover;
	margin-bottom: -7px;
}
.overlay{
	position:absolute;
}
.btn-header1{
	background: linear-gradient(70deg, #FFEC43 13.75%, rgba(255, 230, 4, 0.00) 181.64%), #FFF;
	box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 4px 0px rgba(45, 35, 66, 0.40), 0px 7px 12px -3px rgba(0, 0, 0, 0.25);
	padding: 10px 25px;
	border-radius: 54px;
	font-weight: 700;
	font-size: 18px;
	cursor: pointer;
	color: #000;
}
.btn-header1:hover{
	color: #FFF;
	background: radial-gradient(100% 100% at 100% 0%, #712A7a 0%, #712A7E 100%);
	box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 4px 0px rgba(45, 35, 66, 0.40), 0px 7px 12px -3px rgba(0, 0, 0, 0.25);
}
.btn-hero{
	position: absolute;
	bottom: 100px;
	right: 200px;
	border-radius: 54px;
	background: linear-gradient(70deg, #FFEC43 13.75%, rgba(255, 230, 4, 0.00) 181.64%), #FFF;
	box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 4px 0px rgba(45, 35, 66, 0.40), 0px 7px 12px -3px rgba(0, 0, 0, 0.25);
	width: 305px;
	height: 60px;
	padding: 15px 40px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	text-align: center;
	color: #000;
	z-index: 2;
	font-weight: 700;
	font-size: 20px;
	cursor: pointer;
	color: #535353;
}
.btn-hero:hover{
	color: #fff;
	border-radius: 54px;
	background: radial-gradient(100% 100% at 100% 0%, #712A7a 0%, #712A7E 100%);
	box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 4px 0px rgba(45, 35, 66, 0.40), 0px 7px 12px -3px rgba(0, 0, 0, 0.25);
}
.btn-about{
	border-radius: 54px;
	background: linear-gradient(70deg, #FFEC43 13.75%, rgba(255, 230, 4, 0.00) 181.64%), #FFF;
	box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 4px 0px rgba(45, 35, 66, 0.40), 0px 7px 12px -3px rgba(0, 0, 0, 0.25);
	width: 305px;
	height: 63px;
	padding: 15px 40px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	flex-shrink: 0;
	text-align: center;
	color: #000;
	z-index: 2;
	font-weight: 700;
	font-size: 20px;
	cursor: pointer;
	color: #535353;
	margin-top: 50px;
}
.btn-about:hover {
	color: #fff;
	border-radius: 54px;
	background: radial-gradient(100% 100% at 100% 0%, #712A7a 0%, #712A7E 100%);
	box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 4px 0px rgba(45, 35, 66, 0.40), 0px 7px 12px -3px rgba(0, 0, 0, 0.25);
}
.btn-banner-promo{
	border-radius: 54px;
	background: linear-gradient(70deg, #FFEC43 13.75%, rgba(255, 230, 4, 0.00) 181.64%), #FFF;
	box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 4px 0px rgba(45, 35, 66, 0.40), 0px 7px 12px -3px rgba(0, 0, 0, 0.25);
	width: 305px;
	height: 73px;
	padding: 20px 40px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	flex-shrink: 0;
	text-align: center;
	color: #000;
	z-index: 2;
	font-weight: 700;
	font-size: 20px;
	cursor: pointer;
	color: #535353;
}
.btn-banner-promo:hover{
	color: #fff;
	border-radius: 54px;
	background: radial-gradient(100% 100% at 100% 0%, #5DCCC5 0%, #25908A 100%);
	box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 4px 0px rgba(45, 35, 66, 0.40), 0px 7px 12px -3px rgba(0, 0, 0, 0.25);
}
.content{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
}
.overlay{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(52, 45, 113, .4);
}
.hero-title{
	position: relative;
	left: 0px;
	top: 0px;
	transform: rotate(-90deg);
	font-size: 60px;
}
.hero-date{
	display: flex;
	justify-content: center;
	align-items: center;
}
.container-bannerAbout{
	width: 1200px;
	max-width: 90%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0px;

}
.bannerAbout{
	width: 100%;
	display: flex;
	margin: 50px auto 200px;
	
}
.bannerAbout1{
	display: none;
	
}
.variant{
	padding: 25px 25px;
}
.variant2{
	background: linear-gradient(70deg, #FFEC43 13.75%, rgba(255, 230, 4, 0.00) 181.64%), #FFF;
	box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 4px 0px rgba(45, 35, 66, 0.40), 0px 7px 12px -3px rgba(0, 0, 0, 0.25);
	
}
.titles-certification{
	text-align: center;
}
.titles-certification p{
	color: #00ACA1 ;
	margin-bottom: 7px;
}
.certifications{
	width: 100%;
	margin: -100px auto -10px;
	
}
.container-certifications{
	display: flex;
	justify-content: space-around;
	/* 	flex-wrap: wrap; */
	margin-bottom: 100px;
}
.bullets-certification{
	align-items: start;
	width: 100%;
	
}
.img-certification{
	width: 60%;
	margin: 10px 0px;
}

.cert{
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 50px 0px;
}
.items{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}
.item-text{
	text-align: center;
	width: 300px;
	margin: 5px 120px;
}
.item-img{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top:-15px;
}
.item-text img{
	width: 100%;
}
.img-cert{
	width: 300px;
	margin: 0px 120px;
}
.cert-subtitle{
	text-align: center;
}
.container-form{
	width: 1200px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 0 auto;
	padding: 50px 0;
	gap: 20px;
}
/* .header-form-img{
	width: 700px;
	z-index: 1;
} */

.certification-contact-text{
	text-align: center;
	/* width: 30%; */
}
.certification-contact-text h3{
	color: #00ACA1;
	font-size: 40px;
	font-weight: 700;
	line-height: normal;
}
.certification-contact-text p{
	font-style: normal;
	line-height: normal;
	font-size: 20px;
}
.form-spacing{
	display: flex;
	flex-direction: column;
	justify-self: end;
	
}
.form-iframe-page{
	width: 720px;
	height: 615px;
}
.form-iframe-modal{
	width: 531px;
	height: 615px;
}
.strike{
	text-decoration:line-through;
	font-size: 30px;
	margin-top: 20px;
	color:#123454A6;
}
.aliance{
	font-size: 13px;
	font-style: italic;
	color:#2F2F2F;
	font-weight: 400 ;
}
.btn-x{
	position: relative;
	top: 60px;
}


.navigation li a.active {
	border-bottom: 1px solid #123454;
  }
@media(max-width:1023px) {
	.slider-one {
		height: 600px;
	}

	.slider-one_slide {
		height: 600px;
		padding: 100px 0px;
	}
}

@media(max-width:991px) {
	.slider-one {
		height: 600px;
	}

	.slider-one_slide {
		height: 600px;
	}
}

.slider-one_image-layer:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	opacity: 0.70;
	z-index: 1;
	/* background: url(../images/main-slider/pattern-layer.jpg); */
	background-size: cover;
}

.slider-one_pattern {
	position: absolute;
	right: -50px;
	top: 0px;
	z-index: 2;
	width: 375px;
	height: 1007px;
	background-repeat: no-repeat;
}

.slider-one_pattern-two {
	position: absolute;
	right: 0px;
	z-index: 2;
	width: 100%;
	height: 472px;
	bottom: -130px;
	background-position: center center;
	background-repeat: repeat-x;
	/* animation: 35s linear 0s infinite normal none running slide; */
}

/* @keyframes slide{
	from { background-position: 0 0; }
    to { background-position: 3000px 0; }
} */

.slider-one_image-layer {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	background-size: cover;
	width: 100%;
	height: 100%;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 6000ms linear;
	-moz-transition: all 6000ms linear;
	-ms-transition: all 6000ms linear;
	-o-transition: all 6000ms linear;
	transition: all 6000ms linear;
}

.slider-one .slick-slide.slick-active.slick-current .slider-one_image-layer {
	-webkit-transform: scale(1.15);
	-ms-transform: scale(1.15);
	transform: scale(1.15);
}

.slider-one_content {
	position: relative;
	z-index: 1;
}

.slider-one_content-inner {
	position: relative;
	padding-left: var(--padding-left-60);
}

.slider-one_date {
	display: flex ;
	justify-content: space-between;
	align-items: center;
	width: 300px;
	height: 60px;
	position: relative;
	font-weight: 700;
	line-height: 24px;
	color: var(--white-color);
	font-size: var(--font-40);
	transform: scaleY(0);
	transform-origin: top;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}
.day, .day2{
	font-size: 70px;
}
.month, .month2{
	font-size: 25px;
}

.slider-one .slick-slide.slick-active.slick-current .slider-one_date {
	opacity: 1;
	transform: scaleY(1);
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.slider-one_title {
	position: absolute;
	left: -55px;
	top: 150px;
	opacity: 0;
	font-weight: 300;
	letter-spacing:6px;
	font-size: var(--font-54);
	color: var(--white-color);
	transform: rotate(-90deg);
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
	text-transform: uppercase;
	line-height: 50px;
	margin-bottom: var(--margin-bottom-20);
}

.slider-one .slick-slide.slick-active.slick-current .slider-one_title {
	opacity: 1;
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.slider-one_heading {
	opacity: 0;
	transform: scaleY(0);
	transform-origin: top;
	color: var(--white-color);
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
	margin-top: var(--margin-top-30);
	margin-bottom: var(--margin-bottom-50);
	line-height: 1.1em;
}

.slider-one_heading span {
	color: #fff;
	font-weight: 400;
}

.slider-one .slick-slide.slick-active.slick-current .slider-one_heading {
	opacity: 1;
	transform: scaleY(1);
	-webkit-transition-delay: 600ms;
	-moz-transition-delay: 600ms;
	-ms-transition-delay: 600ms;
	-o-transition-delay: 600ms;
	transition-delay: 600ms;
}

.slider-one .slider-one_buttons {
	opacity: 0;
	transform: scaleY(0);
	transform-origin: top;
	display: inline-block;
	-webkit-transition: all 100ms ease;
	-moz-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
	transition: all 900ms ease;
}

.slider-one .slider-one_buttons .theme-btn {
	margin-right: var(--margin-right-15);
}

.slider-one .slick-slide.slick-active.slick-current .slider-one_buttons {
	opacity: 1;
	transform: scaleY(1);
	transition-delay: 1200ms;
	margin-left: 810px;
}

.slider-one .owl-dots {
	display: none;
}

.slider-one:hover .owl-nav {
	opacity: 1;
}

.slider-one .slick-arrow {
	position: absolute;
	right: 80px;
	left: auto;
	top: 50%;
	opacity: 0;
	z-index: 3;
	margin-top: -130px;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.slider-one .slick-prev,
.slider-one .slick-next {
	display: block;
	width: 55px;
	height: 55px;
	line-height: 70px;
	margin: 10px 0px;
	border-radius: 50px;
	text-align: center;
	color: var(--color-two);
	font-size: var(--font-20);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	/* background-color: rgba(var(--white-color-rgb), 0.70);
	opacity: 1; */
	font-size: 0;
}

.slider-one .slick-prev {
	top: 44%;
}

@media(max-width:1023px) {
	.slider-one .slick-prev {
		top: 42%;
	}

	.slider-one .slick-next {
		top: 52%;
	}


	.slider-one .slick-arrow {
		right: 50px;
	}

}

@media(max-width:767px) {
	.slider-one .slick-prev {
		display: none!important;
	}

	.slider-one .slick-next {
		display: none!important;
	}
	.separated{
		margin: 0px 0px;
	}

}

.slider-one .slick-prev:before {
	content: "\e629";
	font-family: "themify";
	line-height: normal;
	color: var(--color-two);
}

.slider-one .slick-next:before {
	content: "\e628";
	font-family: "themify";
	line-height: normal;
	color: var(--color-two);
}

.slider-one .slick-prev:hover,
.slider-one .slick-next:hover {
	color: var(--main-color);
	background-color: var(--white-color);
}

/*** 

====================================================================
	About One
====================================================================

***/

.about-one {
	position: relative;
	padding: 50px 0px 100px;
	background-color: transparent;
}

.about-one.style-two {
	padding-top: var(--padding-top-120);
}

.about-one_pattern-one {
	position: absolute;
	left: 0px;
	top: 240px;
	width: 69px;
	height: 194px;
	background-repeat: no-repeat;
}

.about-one_pattern-two {
	position: absolute;
	right: 0px;
	top: 370px;
	width: 69px;
	height: 194px;
	background-repeat: no-repeat;
}

.about-one_pattern-three {
	position: absolute;
	right: 10%;
	top: 15%;
	width: 112px;
	height: 115px;
	background-repeat: no-repeat;
}

.about-one_image-column {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.about-one_image-inner {
	position: relative;
	padding-right: var(--padding-right-70);
}

.about-one_image {
	position: relative;
	overflow: hidden;
	border-radius: 50%;
	margin-left: -110px;
	display: inline-block;
	/* border: 15px solid var(--color-four); */
}

.about-one_pattern-four {
	position: absolute;
	left: -200px;
	top: -50px;
	right: 0px;
	bottom: -50px;
	background-repeat: no-repeat;
}

/* Video One Play */

.slider-one_play {
	position: absolute;
	right: 100px;
	top: 20px;
	width: 130px;
	height: 130px;
	text-align: center;
	border-radius: 50%;
	line-height: 130px;
	border: 10px solid var(--white-color);
	-webkit-animation: icon-bounce 0.8s ease-out infinite;
	animation: icon-bounce 0.8s ease-out infinite;
}

.slider-one_play span {
	position: relative;
	width: 110px;
	height: 110px;
	z-index: 10;
	text-align: center;
	display: block;
	line-height: 110px;
	border-radius: 50%;
	font-size: var(--font-28);
	color: var(--white-color);
	padding-left: var(--padding-left-5);
	background-color: #00aca1;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.slider-one_play span:hover {
	color: var(--white-color);
	background-color: var(--color-two);
}

.slider-one_play .ripple,
.slider-one_play .ripple:before,
.slider-one_play .ripple:after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 130px;
	height: 130px;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-border-radius: 50% 50% 50% 50%;
	-moz-border-radius: 50% 50% 50% 50%;
	-ms-border-radius: 50% 50% 50% 50%;
	-o-border-radius: 50% 50% 50% 50%;
	border-radius: 50% 50% 50% 50%;
	-webkit-box-shadow: 0 0 0 0 rgba(0, 146, 255, .3);
	-moz-box-shadow: 0 0 0 0 rgba(0, 146, 255, .3);
	-ms-box-shadow: 0 0 0 0 rgba(0, 146, 255, .3);
	-o-box-shadow: 0 0 0 0 rgba(0, 146, 255, .3);
	box-shadow: 0 0 0 0 rgba(0, 146, 255, .3);
	-webkit-animation: ripple 3s infinite;
	-moz-animation: ripple 3s infinite;
	-ms-animation: ripple 3s infinite;
	-o-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
}

.slider-one_play .ripple:before {
	-webkit-animation-delay: .9s;
	-moz-animation-delay: .9s;
	-ms-animation-delay: .9s;
	-o-animation-delay: .9s;
	animation-delay: .9s;
	content: "";
	position: absolute;
}

.slider-one_play .ripple:after {
	-webkit-animation-delay: .6s;
	-moz-animation-delay: .6s;
	-ms-animation-delay: .6s;
	-o-animation-delay: .6s;
	animation-delay: .6s;
	content: "";
	position: absolute;
}

@-webkit-keyframes ripple {
	70% {
		box-shadow: 0 0 0 70px rgba(253, 167, 0, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(253, 167, 0, 0);
	}
}

@keyframes ripple {
	70% {
		box-shadow: 0 0 0 70px rgba(253, 167, 0, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(253, 167, 0, 0);
	}
}

.about-one_content-column {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.about-one_content-column .sec-title {
	margin-bottom: var(--margin-bottom-20);
}
.event-dates{
	display: flex;
	justify-content: space-evenly;
	margin-top: 80px;
	margin-bottom:-90px;
}
.sec-title_text1, .sec-title_text2{
	width: 300px;
	color: var(--Gris, #535353);
text-align: center;
font-family: Mulish;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 123.333%; /* 24.667px */
}
.sec-title_text1 span{
	color:#336798 ;
}
.sec-title_text2 span{
	color: rgba(0, 172, 161, 0.76);
}
.about-one_content-inner {
	position: relative;
	display: flex;
	flex-direction: column;
}

.about-one_bold-text {
	position: relative;
	font-weight: 700;
	line-height: 36px;
	color: var(--color-two);
	font-size: var(--font-20);
	margin-bottom: var(--margin-bottom-20);
}

.about-one_text {
	position: relative;
	line-height: 25px;
	color: #000;
	font-size: 20px;
	margin-bottom: var(--margin-bottom-40);
}

.about-one_block {
	position: relative;
	margin: 20px auto;
}

.about-one_block-inner {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-16);
	padding-left: var(--padding-left-65);
	width: 500px;
}

.about-one_block-icon {
	position: absolute;
	left: 0px;
	top: 0px;
	line-height: 1em;
	color: #00aca1;
	font-size: var(--font-50);
	font-family: "Flaticon";
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}
.bolder{
	font-weight: 700;
}

.about-one_block-inner:hover .about-one_block-icon {
	transform: rotateY(180deg);
}

.about-one_block strong {
	position: relative;
	display: block;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-24);
	margin-bottom: var(--margin-bottom-10);
}

.about-one_button {
	position: relative;
	margin-top: var(--margin-top-10);
	align-self: flex-end;
}

/*** 

====================================================================
	Counter One
====================================================================

***/

.counter-one {
	position: relative;
	background-color: transparent;
	box-shadow: none;
}

.counter-one .inner-container {
	position: relative;
	padding: 90px 0px 60px;
}

.counter-one_image {
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: 200%;
	overflow: hidden;
	border-radius: 0px 100px 0px 0px;
	background-size: cover;
}

.counter-one_image::before {
	position: absolute;
	content: '';
	right: 0px;
	top: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.80;
	
}

.counter-one_block {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.counter-one_inner {
	position: relative;
}

.counter-one_counter h4{
	position: relative;
	font-weight: 700;
	color: var(--white-color);
	font-size: var(--font-40);
}

.counter-one_text {
	display: block;
	position: relative;
	letter-spacing: .1rem;
	color: var(--white-color);
	font-size: var(--font-18);
	margin-top: var(--margin-top-5);
}

/*** 

====================================================================
	Speaker One
====================================================================

***/
.centered-speakers{
	display: flex;
	justify-content: center;
	cursor: pointer;
}

.speakers-one {
	position: relative;
	overflow: hidden;
	padding: 110px 0px 70px;
	background-color: transparent;
}

.speakers-one_pattern {
	position: absolute;
	right: -200px;
	top: 90px;
	width: 475px;
	height: 460px;
	background-repeat: no-repeat;
}

.speakers-one_pattern-two {
	position: absolute;
	left: -40px;
	top: 64%;
	width: 112px;
	height: 115px;
	background-repeat: no-repeat;
}

.speaker-block_one {
	position: relative;
	margin-bottom: var(--margin-bottom-40);
}

.speaker-block_one-inner {
	position: relative;
}

.speaker-block_one-image {
	position: relative;
	width: 270px;
	height: 270px;
	padding: 5px 5px;
	border-radius: 50%;
	/* border: 3px solid #fff; */
	box-shadow: inset 0 0 0 6px #fff;
	/* box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10); */
}

.speaker-block_one-image img {
	position: relative;
	border-radius: 50%;
}

.speaker-block_one-image::before {
	display: none;
	position: absolute;
	content: '';
	left: 10px;
	top: 10px;
	right: 5px;
	bottom: -15px;
	border-radius: 0px 50% 50% 50%;
	/* border: 2px dashed var(--color-two); */
}

.speaker-block_one-social {
	position: absolute;
	left: 20px;
	top: 20px;
	z-index: 2;
	bottom: 20px;
	overflow: hidden;
	border-radius: 50px;
}

.speaker-block_one-share {
	position: relative;
	width: 40px;
	height: 40px;
	z-index: 2;
	cursor: pointer;
	line-height: 40px;
	border-radius: 50%;
	text-align: center;
	display: inline-block;
	font-size: var(--font-16);
	color: var(--white-color);
	background-color: #00aca1;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.speaker-block_one-social-list {
	position: absolute;
	left: 0px;
	top: -100%;
	width: 40px;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.speaker-block_one-inner:hover .speaker-block_one-social-list {
	top: 50px;
}

.speaker-block_one-social-list a {
	position: relative;
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	border-radius: 50px;
	display: inline-block;
	font-size: var(--font-16);
	color: var(--black-color);
	background-color: var(--white-color);
	margin-bottom: var(--margin-bottom-5);
}

.speaker-block_one-social-list a.youtube {
	color: #ff0000;
}

.speaker-block_one-social-list a.twitter {
	color: #1da1f2;
}

.speaker-block_one-social-list a.facebook {
	color: #4267b2;
}

.speaker-block_one-social-list a.instagram {
	color: #e73a67;
}

.speaker-block_one-pattern {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: -30px;
	height: 129px;
	background-position: center center;
	background-repeat: no-repeat;
}

.speaker-block_one-content {
	position: relative;
	text-align: center;
	padding-top: var(--padding-top-45);
}

.speaker-one_title {
	position: relative;
	font-weight: 700;
}

.speaker-one_title a {
	position: relative;
	color: var(--color-two);
}

.speaker-one_title a:hover {
	color: var(--main-color);
}

.speaker-one_detail {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-18);
	margin-top: var(--margin-top-10);
}
.popup-mobile{
	display: none;
}


/*** 

====================================================================
	Feature One
====================================================================

***/

.feature-one {
	position: relative;
	padding: 110px 0px 80px;
	background-color: var(--color-seven);
}

.feature-one_pattern {
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	width: 461px;
	background-position: center right;
	background-repeat: no-repeat;
}

.feature-one_pattern-two {
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: 461px;
	background-position: center right;
	background-repeat: no-repeat;
}

.feature-one_pattern-three {
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: 381px;
	background-position: center right;
	background-repeat: no-repeat;
}

.feature-block_one {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.feature-block_one-inner {
	position: relative;
	padding-right: var(--padding-right-50);
}

.feature-block_one:last-child .feature-block_one-inner::before {
	display: none;
}

.feature-block_one-inner::before {
	position: absolute;
	content: '';
	right: 40px;
	top: 0px;
	width: 2px;
	bottom: 0px;
	background-color: var(--white-color);
}

.feature-block_one-icon::after {
	position: absolute;
	content: '';
	left: 0px;
	top: -10px;
	width: 88px;
	height: 93px;
	z-index: -1;
	background: url(../images/icons/feature-bg.png) no-repeat;
}

.feature-block_one-icon {
	position: relative;
	line-height: 1em;
	z-index: 1;
	display: inline-block;
	font-size: var(--font-60);
	color: var(--color-four);
	font-family: "Flaticon";
}

.feature-block_one-inner:hover .feature-block_one-icon {
	animation: icon-bounce 0.8s ease-out;
	-webkit-animation: icon-bounce 0.8s ease-out;
}

.feature-block_one-title {
	position: relative;
	font-weight: 700;
	margin-top: var(--margin-top-35);
}

.feature-block_one-title a {
	position: relative;
	color: var(--color-two);
}

.feature-block_one-title a:hover {
	color: var(--color-four);
}

.feature-block_one-more {
	position: relative;
	font-weight: 700;
	display: inline-block;
	color: var(--main-color);
	margin-top: var(--margin-top-20);
}

/*** 

====================================================================
	Leadership One
====================================================================

***/

.leadership-one {
	position: relative;
	overflow: hidden;
	padding: 110px 0px 110px;
}

.leadership-one_icon {
	position: absolute;
	left: 10%;
	top: 10%;
	width: 40px;
	height: 59px;
	background-repeat: no-repeat;
}

.leadership-one_icon-two {
	position: absolute;
	right: 20%;
	top: 10%;
	width: 189px;
	height: 135px;
}

.leadership-one_icon-three {
	position: absolute;
	left: 10%;
	top: 45%;
	width: 104px;
	height: 66px;
}

.leadership-one_icon-four {
	position: absolute;
	left: 15%;
	top: 85%;
	width: 110px;
	height: 111px;
}

.leadership-one_icon-five {
	position: absolute;
	right: -80px;
	top: 5%;
	width: 50%;
	height: 100%;
	background-position: right top;
	background-repeat: no-repeat;
}

/*** 

====================================================================
	Product Tabs Style
====================================================================

***/

.leadership-tabs {
	position: relative;
	margin-top: var(--margin-top-30);
}

.leadership-tabs .tab-btns {
	position: relative;
}

.leadership-tabs .tab-btns .tab-btn .nav-link{
	position: relative;
	line-height: 24px;
	font-weight: 500;
	cursor: pointer;
	display: inline-block;
	color: var(--color-five);
	font-size: var(--font-14);
	text-transform: capitalize;
	transition: all 500ms ease;
	padding: 22px 80px 22px 35px;
	margin-right: var(--margin-right-10);
	background: url(../images/background/leadership-1.png) left center no-repeat;
}

.leadership-tabs .tab-btns .tab-btn a{
	padding: 0;
	background: none;
	border: 0;
}

.leadership-tabs .tab-btns .tab-btn span {
	position: relative;
	display: block;
	font-weight: 700;
	font-size: var(--font-18);
	text-transform: uppercase;
	color: var(--color-two);
	margin-top: var(--margin-top-5);
}

.leadership-tabs .tab-btns .tab-btn:nth-child(2) .nav-link{
	background: url(../images/background/leadership-2.png) left center no-repeat;
}

.leadership-tabs .tab-btns .tab-btn:nth-child(3) a{ 
	color: var(--white-color);
}

.leadership-tabs .tab-btns .tab-btn:nth-child(3) .nav-link{
	color: var(--white-color);
	background: url(../images/background/leadership-3.png) left center no-repeat;
}

.leadership-tabs .tab-btns .tab-btn:nth-child(3) span {
	color: var(--white-color);
}

.leadership-tabs .tab-btns .tab-btn:nth-child(4) .nav-link{
	color: var(--white-color);
	background: url(../images/background/leadership-4.png) left center no-repeat;
}


.leadership-tabs .tab-btns .tab-btn:nth-child(4) a{ 
	color: var(--white-color);
}


.leadership-tabs .tab-btns .tab-btn:nth-child(4) span {
	color: var(--white-color);
}

.leadership-tabs .tab-btns .tab-btn:hover,
.leadership-tabs .tab-btns .tab-btn.active-btn {}

.leadership-tabs .tab-btns .tab-btn:last-child {
	margin-right: var(--margin-zero);
}

.leadership-tabs .tabs-content {
	position: relative;
	padding: 40px 0px 0px;
}

.leadership-tabs .tab-btns {
	border: 0;
}

.leadership-tabs .tab-btns a{
    color: var(--color-five);
}

.leadership-tabs .tabs-content .tab {
	position: relative;
	display: none;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transform: translateY(80px);
}

.leadership-tabs .tabs-content .tab.active-tab {
	display: block;
	transform: translateY(0px);
}

.leadership-one_block {
	position: relative;
}

.leadership-one_block-inner:hover {
	border-color: rgba(255, 195, 0, 0.30);
	box-shadow: inset 0px 0px 15px rgba(255, 195, 0, 0.30);
}

.leadership-one_block-inner {
	position: relative;
	padding: 60px 60px;
	background-color: var(--white-color);
	border: 3px solid rgba(0, 0, 0, 0.07);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.leadership-one_block-content {
	position: relative;
	padding-left: 260px;
}

.leadership-one_block-image {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 210px;
	height: 210px;
	padding: 5px 5px;
	border-radius: 50% 50% 0% 50%;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.leadership-one_block-image img {
	position: relative;
	overflow: hidden;
	border-radius: 50% 50% 0% 50%;
}

.leadership-one_block-icon {
	position: absolute;
	right: -20px;
	bottom: 25px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: var(--font-20);
	color: var(--color-three);
	font-family: "Flaticon";
	background-color: var(--color-seven);
}

.leadership-one_block-time {
	position: relative;
	font-weight: 700;
	color: var(--color-three);
	font-size: var(--font-16);
}

.leadership-one_block-name {
	position: relative;
	font-weight: 700;
	color: var(--color-four);
	font-size: var(--font-20);
	margin-top: var(--margin-top-15);
}

.leadership-one_block-title {
	position: relative;
	font-weight: 700;
	margin-top: var(--margin-top-15);
}

.leadership-one_block-title a {
	position: relative;
	color: var(--color-two);
}

.leadership-one_block-title a:hover {
	color: var(--color-three);
}

.leadership-one_block-text {
	position: relative;
	line-height: 34px;
	color: var(--color-five);
	font-size: var(--font-18);
	margin-top: var(--margin-top-25);
}

.leadership-one_block.style-two .leadership-one_block-content {
	padding-right: 260px;
	padding-left: var(--padding-zero);
}

.leadership-one_block.style-two .leadership-one_block-image {
	right: 0px;
	left: auto;
	border-radius: 50% 50% 50% 0%;
}

.leadership-one_block.style-two .leadership-one_block-image img {
	border-radius: 50% 50% 50% 0%;
}

.leadership-one_block.style-two .leadership-one_block-icon {
	left: -20px;
	right: auto;
}

/*** 

====================================================================
	Gallery One
====================================================================

***/

.gallery-one {
	position: relative;
}

.gallery-one .gallery-one_block {
	float: left;

	padding: var(--padding-zero);
}

.gallery-one_block {
	position: relative;
}

.gallery-one_block-inner {
	position: relative;
}

.gallery-one_block-image {
	border: 2px solid #fff;
	position: relative;
	cursor: pointer;
}

.gallery-one_block-image img {
	position: relative;
	width: 100%;
	display: block;
}

.gallery-one_block-overlay {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(var(--color-four-rgb), 0.50);

	transition: -webkit-transform 0.4s ease;
	transition: transform 0.4s ease;
	transition: transform 0.4s ease, -webkit-transform 0.4s ease;
	-webkit-transform: scale(0, 1);
	-ms-transform: scale(0, 1);
	transform: scale(0, 1);
	-webkit-transform-origin: right center;
	-ms-transform-origin: right center;
	transform-origin: right center;
}

.gallery-one_block-inner:hover .gallery-one_block-overlay {
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	transform: scale(1, 1);
	-webkit-transform-origin: left center;
	-ms-transform-origin: left center;
	transform-origin: left center;
}

.gallery-one_block-icon {
	position: relative;
	font-size: var(--font-40);
	color: var(--white-color);
	font-family: "Flaticon";
}

.gallery-one_block-icon:hover {
	color: var(--color-three);
}

/*** 

====================================================================
	Price One
====================================================================

***/

.container-pricing{
	display: flex;
	flex-direction: column;
	flex-wrap:wrap;
	justify-content: center;
	align-items: center; 
}
.container-rules{
	max-width: 1200px;
	width: 90%;
	margin: auto;
}
.pricing-header{
	margin-top: 100px;
	text-align: center;
	margin-bottom: 50px;
}
.pricing-header p{
	color: #00aca1;
    font-size: var(--font-16);
	font-weight: 700;
	margin-bottom: 5px;
}
.pricing-container{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 200px;
}
.pricing-card{
	width: 400px;
	text-align: center;
	justify-content: center;
	max-height: 900px;
	margin: 20px 10px;
}
.pricing-card h3{
	font-size: 16px;
	line-height: 20px;
	margin: 10px auto;
	width: 300px;
}
.price-advisor1 span{
	color: #123454;
}
.price-advisor2 span{
	color: #00aca1;
}
.pricing-card h3{
	font-size: 16px;
	line-height: 20px;
	margin: 10px auto;
	width: 300px;
}
.card-price{
	width: 350px;
	height:760px;
	border-radius: 20px;
	padding: 20px 15px;
	margin: 0 auto;
	background-color: #fff;
}
.pricing-card .card-price_blue{
	border-radius: 25px;
	border: 2px solid var(--Azul, #123454);
	background: var(--Blanco, #FFF);
	box-shadow: 0px 4px 28.5px 0px rgba(0, 0, 0, 0.25);
}
.pricing-card .card-price_blue .plan1{
	background-color: #f5f5f5;
	padding: 5px 10px;
	border: 2px solid #123454;
	color: #123454;
	border-radius: 20px;
	font-size: 16px;
	font-weight: 700;
}
.pricing-card .card-price_aqua .first-img-price{
	margin-top: 20px;
}
.pricing-card .card-price_aqua{
	border-radius: 25px;
	border: 2px solid var(--Verde, #00ACA1);
	background: #FFF;
	box-shadow: 0px 4px 28.5px 0px rgba(0, 0, 0, 0.25);
}
.pricing-card .card-price_aqua .real-price{
 color: #00aca1;
}

.pricing-card .card-price_aqua .plan2{
	background-color: #f5f5f5;
	padding: 5px 10px;
	border: 2px solid #00aca1;
	color: #00aca1;
	border-radius: 20px;
	font-size: 16px;
	font-weight: 700;
}
.card-price p:first-child{
	display: inline-block;
	background-color: #f5f5f5;
	padding: 5px 10px;
	border: 2px solid blue;
	border-radius: 20px;
}

.first-img-price, .second-img-price{
	padding: 30px 10px 15px;
}
.disacount-price{
	font-size: 33px;
	color: rgba(18, 52, 84, 0.65);
	text-decoration: line-through;
	font-weight: 300;
	margin-bottom: 2px;
}
.aliance-price{
	color: #2F2F2F;
	font-style: italic;
	line-height: 12px;
	font-size: 16px;
	margin-bottom: -5px;
}
.real-price{
	color: #123454;
	font-size: 50px;
	font-style: normal;
	font-weight: 600;
	margin-bottom: 10px;
	padding: 40px 0;
}
.disclaimer{
	color: #2F2F2F;
	text-align: center;
	font-size: 13px;
	font-style: italic;
	font-weight: 400;
	margin-top: 50px;
}




.pricing-flex{
	display: flex;
	justify-content: center;
}
.price-one {
	position: relative;
	padding: 110px 0px 200px;
	background-color: transparent;
}
.price-one_pattern {
	position: absolute;
	left: -260px;
	top: 90px;
	width: 475px;
	height: 460px;
	background-repeat: no-repeat;
}

.price-block_one {
	width: 371px;
	margin: 50px auto;
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}
/* .right{
	border: 2px solid #00aca1;
}
 */
.price-block_one-inner:first-child {
	border-radius: 25px;
	position: relative;
	padding: 50px 30px;
	background-color: var(--white-color);
	text-align: center;
	border: 2px solid #00aca1;
	height: 100%;
}


.price-block_one-pattern {
	position: absolute;
	right: 0px;
	top: 0px;
	left: 0px;
	height: 167px;
	background-repeat: no-repeat;
}

.price-block_one-pattern-two {
	position: absolute;
	left: 0px;
	bottom: 15px;
	width: 199px;
	height: 49px;
	background-repeat: no-repeat;
}

.price-block_one-title {
	position: relative;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-14);
	text-align: center;
	display: inline;
	border: 2px solid #123454;
	padding: 5px 10px;
	border-radius: 21.5px;
	background-color: #E2F6F5;
}

.price-block_one-price {
	position: relative;
	font-weight: 600;
	line-height: 1em;
	color: #123454;
	font-size: var(--font-48);
	margin-bottom: var(--margin-bottom-25);
}

.price-block_one-price span {
	position: relative;
	letter-spacing: 1px;
	font-size: var(--font-16);
	text-transform: uppercase;
	color: var(--color-five);
}

.price-block_one-options {
	position: relative;
	margin-top: var(--margin-top-10);
}

.price-block_one-options li {
	position: relative;
	display: block;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-bottom: var(--margin-bottom-20);
}

.price-block_one-options li strong {
	position: relative;
	display: block;
	font-weight: 700;
	color: var(--color-two);
	margin-bottom: var(--margin-bottom-5);
}

.price-block_one-options li.light {
	opacity: 0.70;
}

.price-block_one-options li:last-child {
	margin-bottom: var(--margin-zero);
}

.price-block_one-button {
	position: relative;
	margin-top: var(--margin-top-30);
	display: flex;
	justify-content: flex-end;
}
.price-block_one-button .banner-final {
	position: relative;
	margin-top: var(--margin-top-30);
	display: flex;
	justify-content: flex-end;
}

.price-block_one-charges {
	position: relative;
	font-weight: 600;
	color: #00ACA1;
	font-size: var(--font-13);
	margin-top: var(--margin-top-40);
	line-height: 19.24px;
	font-style: italic;
}

.price-block_one:nth-child(2) .price-block_one-price {
	color: #00aca1;
	margin-bottom: 100px;
}

.price-block_one:nth-child(2) .price-block_one-button .theme-btn {
	/* background-color: var(--color-three); */
	background: rgb(37,144,138);
	background: linear-gradient(45deg, rgba(37,144,138,1) 55%, rgba(93,204,197,1) 93%);
	box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.20) inset, 0px 2px 4px 0px rgba(45, 35, 66, 0.40), 0px 7px 12px -3px rgba(0, 0, 0, 0.25);
	margin: 50px 0px 0px;
	display: none;

}

.price-block_one:nth-child(3) .price-block_one-price {
	color: var(--main-color);
}

.price-block_one:nth-child(3) .price-block_one-button .theme-btn {
	background-color: var(--main-color);
}

/*** 

====================================================================
	Video One
====================================================================

***/

.video-one {
	position: relative;
	padding: 120px 0px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-attachment: fixed;
	background-size: cover;
}

.video-one_pattern-one {
	position: absolute;
	left: 0px;
	top: 100px;
	width: 148px;
	height: 210px;
	background-repeat: no-repeat;
}

.video-one_pattern-two {
	position: absolute;
	left: 110px;
	bottom: 15%;
	width: 104px;
	height: 66px;
	background-repeat: no-repeat;
}

.video-one_pattern-three {
	position: absolute;
	right: 50px;
	top: 15%;
	width: 189px;
	height: 135px;
	background-repeat: no-repeat;
}

.video-one_pattern-four {
	position: absolute;
	right: 50px;
	bottom: 15%;
	width: 40px;
	height: 59px;
	background-repeat: no-repeat;
}

.video-one::before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	opacity: 0.80;
	background-color: var(--color-three);
}

/* Video One Play */

.video-one_play {
	position: relative;
	width: 110px;
	height: 110px;
	margin: 0 auto;
	text-align: center;
	border-radius: 50%;
	line-height: 130px;
	display: inline-block;
	margin-top: var(--margin-top-40);
	-webkit-animation: icon-bounce 0.8s ease-out infinite;
	animation: icon-bounce 0.8s ease-out infinite;
}

.video-one_play span {
	position: relative;
	width: 110px;
	height: 110px;
	z-index: 10;
	text-align: center;
	display: block;
	line-height: 110px;
	border-radius: 50%;
	font-size: var(--font-28);
	color: var(--color-four);
	padding-left: var(--padding-left-5);
	background-color: var(--white-color);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.video-one_play span:hover {
	color: var(--white-color);
	background-color: var(--color-two);
}

.video-one_play .ripple,
.video-one_play .ripple:before,
.video-one_play .ripple:after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 110px;
	height: 110px;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-border-radius: 50% 50% 50% 50%;
	-moz-border-radius: 50% 50% 50% 50%;
	-ms-border-radius: 50% 50% 50% 50%;
	-o-border-radius: 50% 50% 50% 50%;
	border-radius: 50% 50% 50% 50%;
	-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .3);
	-moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, .3);
	-ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, .3);
	-o-box-shadow: 0 0 0 0 rgba(255, 255, 255, .3);
	box-shadow: 0 0 0 0 rgba(255, 255, 255, .3);
	-webkit-animation: ripple 3s infinite;
	-moz-animation: ripple 3s infinite;
	-ms-animation: ripple 3s infinite;
	-o-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
}

.video-one_play .ripple:before {
	-webkit-animation-delay: .9s;
	-moz-animation-delay: .9s;
	-ms-animation-delay: .9s;
	-o-animation-delay: .9s;
	animation-delay: .9s;
	content: "";
	position: absolute;
}

.video-one_play .ripple:after {
	-webkit-animation-delay: .6s;
	-moz-animation-delay: .6s;
	-ms-animation-delay: .6s;
	-o-animation-delay: .6s;
	animation-delay: .6s;
	content: "";
	position: absolute;
}

/*** 

====================================================================
	Clients One
====================================================================

***/

.clients-one {
	position: relative;
	padding: 110px 0px;
	background-color: transparent;
}

.clients-one_column {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	position: relative;
	text-align: center;
	margin: 10px -80px;

}
.clients-one_column img{
	width: 50%;
	border-radius: 25px;
	overflow: hidden;
	border: 1px solid #cacaca;
	background-color: #fff;
}

.clients-one_column a {
	position: relative;
	display: block;
	background-color: var(--white-color);
	border: 1px solid rgba(var(--black-color-rgb), 0.07);
}

.clients-one_column a:hover {
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

/*** 

====================================================================
	News One
====================================================================

***/

.news-one {
	position: relative;
	padding: 0px 0px 80px;
}

.news-block_one {
	position: relative;
	z-index: 2;
	margin-bottom: var(--margin-bottom-30);
}

.news-block_one-inner {
	position: relative;
}

.news-block_one-tag {
	position: absolute;
	right: 25px;
	top: 25px;
	z-index: 2;
	font-weight: 500;
	padding: 7px 20px;
	letter-spacing: 1px;
	color: var(--white-color);
	font-size: var(--font-14);
	background-color: var(--color-three);
}

.news-block_one-image {
	position: relative;
	overflow: hidden;
}

.news-block_one-image img:first-child {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	-webkit-transform: translatex(50%) scalex(2);
	transform: translatex(50%) scalex(2);
	opacity: 0;
	-webkit-filter: blur(10px);
	filter: blur(10px);
}

.news-block_one-inner:hover .news-block_one-image img:first-child {
	-webkit-transform: translatex(0) scalex(1);
	transform: translatex(0) scalex(1);
	opacity: 1;
	-webkit-filter: blur(0);
	filter: blur(0);
}

.news-block_one-inner:hover .news-block_one-image img:nth-child(2) {
	-webkit-transform: translatex(-50%) scalex(2);
	transform: translatex(-50%) scalex(2);
	opacity: 0;
	-webkit-filter: blur(10px);
	filter: blur(10px);
}

.news-block_one-image img {
	position: relative;
	width: 100%;
	display: block;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.news-block_one-content {
	position: relative;
	z-index: 10;
	margin-top: -50px;
	padding: 40px 30px 30px;
	background-color: var(--white-color);
	margin-right: var(--margin-right-30);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.news-block_one-date {
	position: absolute;
	left: 30px;
	top: -17px;
	font-weight: 600;
	padding: 6px 15px;
	letter-spacing: 2px;
	color: var(--color-two);
	font-size: var(--font-14);
	text-transform: uppercase;
	background-color: var(--main-color);
}

.news-block_one-meta {
	position: relative;
}

.news-block_one-meta li {
	position: relative;
	display: inline-block;
	color: var(--color-five);
	padding-left: var(--padding-left-25);
	margin-right: var(--margin-right-20);
}

.news-block_one-meta li .icon {
	position: absolute;
	left: 0px;
	top: 4px;
	line-height: 1em;
	color: var(--color-four);
}

.news-block_one-meta li:last-child {
	margin-right: var(--margin-zero);
}

.news-block_one-title {
	position: relative;
	text-transform: capitalize;
	margin-top: var(--margin-top-15);
}

.news-block_one-title a {
	position: relative;
	color: var(--color-two);
}

.news-block_one-title a:hover {
	color: var(--color-four);
}

.news-block_one-text {
	position: relative;
	line-height: 28px;
	font-size: var(--font-16);
	margin-top: var(--margin-top-15);
}

.news-block_one-more {
	position: relative;
	font-weight: 700;
	letter-spacing: 1px;
	display: inline-block;
	color: var(--color-three);
	text-transform: capitalize;
	margin-top: var(--margin-top-15);
	padding-right: var(--padding-right-25);
}

.news-block_one-more:hover {
	color: var(--color-two);
}

.news-block_one-more::before {
	position: absolute;
	content: '\f178';
	right: 0px;
	top: 5px;
	line-height: 1em;
	font-family: 'FontAwesome';
}

/*** 

====================================================================
	Subscribe One
====================================================================

***/

.subscribe-one {
	position: relative;
	z-index: 1;
}

.subscribe-one .inner-container {
	position: relative;
	overflow: hidden;
	padding: 60px 60px 50px;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.subscribe-one_title {
	position: relative;
	font-weight: 700;
	letter-spacing: 1px;
	color: var(--color-three);
	font-size: var(--font-16);
	text-transform: uppercase;
}

.subscribe-one_heading {
	position: relative;
	color: var(--color-two);
	margin-top: var(--margin-top-10);
}

.subscribe-box {
	position: relative;
	margin-top: var(--margin-top-5);
}

.subscribe-box .form-group {
	position: relative;
	margin: 0px;
	width: 100%;
	max-width: 100%;
	overflow: hidden;
	border: 1px solid rgba(var(--black-color-rgb), 0.10);
}

.subscribe-box .form-group input[type="text"],
.subscribe-box .form-group input[type="email"] {
	position: relative;
	line-height: 43px;
	background: none;
	display: block;
	height: 55px;
	width: 100%;
	box-shadow: inherit;
	color: var(--black-color);
	font-size: var(--font-14);
	padding: 10px 55px 10px 25px;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	background-color: var(--white-color);
}

.subscribe-box .form-group input::placeholder,
.subscribe-box .form-group textarea::placeholder {
	color: var(--color-five);
}

.subscribe-box .form-group button {
	position: absolute;
	right: 0px;
	top: 0px;
	font-weight: 600;
	cursor: pointer;
	line-height: 100%;
	text-align: center;
	padding: 21px 45px;
	display: inline-block;
	letter-spacing: 1px;
	font-size: var(--font-14);
	color: var(--color-two);
	text-transform: uppercase;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	background-color: var(--main-color);
}

.subscribe-box .form-group button:hover {
	color: var(--white-color);
	background-color: var(--color-three);
}

.subscribe-box.style-two .form-group {
	position: relative;
	padding: 5px 5px;
	background-color: var(--white-color);
}

.subscribe-box.style-two .form-group input {
	border: none;
}

.subscribe-box.style-two .form-group button {
	right: 5px;
	top: 4px;
	color: var(--white-color);
	background-color: var(--color-two);
}

.subscribe-box.style-three .form-group input {
	background: none;
	color: var(--white-color);
	border: 1px solid var(--white-color);
}

.subscribe-box.style-three .form-group button {
	position: relative;
	margin-top: var(--margin-top-15);
}

.subscribe-box.style-three .form-group input::placeholder,
.subscribe-box.style-three .form-group textarea::placeholder {
	color: var(--white-color);
}

.subscribe-one_pattern {
	position: absolute;
	left: 0px;
	bottom: -20px;
	width: 189px;
	height: 91px;
	background-repeat: no-repeat;
}

.subscribe-one_pattern-two {
	position: absolute;
	right: 0px;
	top: -20px;
	width: 69px;
	height: 91px;
	background-repeat: no-repeat;
}

.subscribe-one_pattern-three {
	position: absolute;
	right: 0px;
	top: -280px;
	width: 281px;
	height: 538px;
	background-repeat: no-repeat;
}

.fix {
	overflow: hidden;
}

#bd-cstm-sliders #bd-cstm-sliders__container {
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	padding: 0;
	overflow: hidden;
	width: 300%;
}

#bd-cstm-sliders #bd-cstm-sliders__container .bd-cstm-slide {
	position: relative;
	width: 100%;
	overflow: hidden;
	height: 100%;
}

/*** 

====================================================================
	Slider Two
====================================================================

***/

.slider-two {
	position: relative;
	overflow: hidden;
}

.slider-two_icon-one {
	position: absolute;
	left: 2%;
	bottom: 55%;
	width: 221px;
	height: 221px;
	z-index: 1;
	opacity: 0.50;
	background-repeat: no-repeat;
}

.slider-two_icon-two {
	position: absolute;
	left: 15%;
	bottom: 40%;
	width: 250px;
	height: 250px;
	z-index: 1;
	opacity: 0.50;
	background-repeat: no-repeat;
}

.slider-two_icon-three {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 100%;
	height: 601px;
	z-index: 1;
	opacity: 0.90;
	background-position: right top;
	background-repeat: no-repeat;
}

.slider-two .owl-dots,
.slider-two .owl-nav {
	display: none;
}

.slider-two_cloud-layer {
	position: absolute;
	left: 0px;
	bottom: -10px;
	right: 0px;
	height: 531px;
	z-index: 1;
	background-repeat: repeat-x;
}

.slider-two .slide {
	position: relative;
	padding: 130px 0px 0px;
}

.slider-two_image-layer {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

.slider-two_image-layer::before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	opacity: 0.90;
	background-color: var(--color-two);
}

.slider-two_image-column {
	position: relative;
	padding-top: var(--padding-top-130);
}

.slider-two_image {
	position: relative;
}

.slider-two_image img {
	max-width: inherit !important;
	width: auto !important;
	margin: 0 auto;
}

.slider-two_content {
	position: relative;
}

.slider-two_content-inner {
	position: relative;
	padding-top: 300px;
}

.slider-two_date {
	position: relative;
	font-weight: 600;
	color: var(--white-color);
	font-size: var(--font-18);
	padding-left: var(--padding-left-35);
}

.slider-two_date::before {
	position: absolute;
	left: 0px;
	content: "\f107";
	font-weight: normal;
	font-family: "Flaticon";
	color: var(--main-color);
}

.slider-two_heading {
	color: var(--white-color);
	margin-top: var(--margin-top-30);
}

.slider-two_heading::before {
	position: absolute;
	content: '';
	left: -70px;
	bottom: -135px;
	width: 101px;
	height: 192px;
	background: url(../images/main-slider/line.png) no-repeat;
}

.slider-two_location {
	position: relative;
	font-size: var(--font-18);
	color: var(--white-color);
	margin-top: var(--margin-top-30);
	padding-left: var(--padding-left-35);
}

.slider-two_location::before {
	position: absolute;
	left: 0px;
	content: "\f13e";
	font-weight: normal;
	font-family: "Flaticon";
	color: var(--main-color);
}

.slider-two_button {
	position: relative;
	margin-top: var(--margin-top-40);
	margin-left: var(--margin-left-50);
}

.slider-two_timer {
	position: absolute;
	right: 50px;
	bottom: 30px;
	z-index: 10;
}

.slider-two .time-countdown {
	position: relative;
}

.slider-two .time-countdown .react-countdown .time-section {
	position: relative;
	z-index: 7;
	line-height: 1em;
	font-weight: 600;
	text-align: center;
	border-radius: 0px;
	margin: 0 30px 20px;
	color: var(--color-two);
	text-transform: uppercase;
	font-size: var(--font-20);
	display: inline-block;
}

.slider-two .time-countdown .react-countdown .time-section .time {
	position: relative;
	display: block;
	line-height: 1em;
	font-weight: 800;
	color: var(--color-two);
	font-size: var(--font-84);
	margin-bottom: var(--margin-bottom-10);
}

.slider-two .time-countdown .react-countdown .time-section .time-text {
	color: var(--color-two);
	text-transform: uppercase;
	font-size: var(--font-20);
	display: inline-block;
}

.slider-two .time-countdown .react-countdown .time-section::before {
	position: absolute;
	content: ':';
	right: -50px;
	top: 5px;
	line-height: 1em;
	font-weight: 800;
	color: var(--color-two);
	font-size: var(--font-100);
}

.slider-two .time-countdown .react-countdown .time-section::before {
	display: none;
}

/*** 

====================================================================
	About One
====================================================================

***/

.about-two {
	position: relative;
	overflow: hidden;
	padding: 110px 0px 50px;
}

.about-two_pattern-one {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-position: left center;
	background-repeat: no-repeat;
}

.about-two_pattern-two {
	position: absolute;
	right: 0;
	top: 0px;
	width: 853px;
	height: 624px;
	opacity: 1;
	background-position: right top;
	background-repeat: no-repeat;
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
}


.about-two_pattern-three {
	position: absolute;
	right: 60px;
	bottom: 60px;
	width: 97px;
	height: 97px;
	background-position: right top;
	background-repeat: no-repeat;
}

.about-two_content-column {
	position: relative;
	margin-bottom: var(--margin-bottom-20);
}

.about-two_content-inner {
	position: relative;
}

.about-two_options {
	position: relative;
	padding: 35px 35px;
	border-radius: 10px;
	background-color: var(--color-seven);
	margin-bottom: var(--margin-bottom-50);
	border-bottom: 3px solid var(--main-color);
}

.about-two_options-list {
	position: relative;
}

.about-two_options-list li {
	position: relative;
	line-height: 30px;
	color: var(--color-five);
	font-size: var(--font-16);
	padding-left: var(--padding-left-60);
	margin-bottom: var(--margin-bottom-20);
}

.about-two_options-list li .icon {
	position: absolute;
	left: 0px;
	top: 6px;
	width: 40px;
	height: 40px;
	font-weight: 700;
	line-height: 40px;
	text-align: center;
	border-radius: 50px;
	display: inline-block;
	font-family: "Flaticon";
	color: var(--color-three);
	border: 1px solid var(--color-five);
}

.about-two_options-list li:last-child {
	margin-bottom: var(--margin-zero);
}

.about-two_signature {
	position: relative;
	line-height: 1em;
	color: var(--color-four);
	font-size: var(--font-60);
	text-transform: capitalize;
	margin-right: var(--margin-right-60);
	font-family: 'Mrs Saint Delafield', cursive;
}

.about-two_author {
	position: relative;
	font-weight: 700;
	letter-spacing: 1px;
	font-size: var(--font-16);
	text-transform: uppercase;
}

.about-two_author span {
	position: relative;
	font-weight: 400;
	display: block;
	text-transform: capitalize;
	margin-top: var(--margin-top-5);
}

.about-two_image-column {
	position: relative;
	margin-bottom: var(--margin-bottom-20);
}

.about-two_image-inner {
	position: relative;
	padding-left: var(--padding-left-50);
}

.about-two_image {
	position: relative;
}

.about-two_image img {
	position: relative;
	max-width: inherit;
}

/* About Two Counter */

.about-two_counter {
	position: absolute;
	left: 50px;
	bottom: 0px;
	width: 304px;
	height: 242px;
	text-align: left;
	background-repeat: no-repeat;
	padding-left: var(--padding-left-45);
	-webkit-animation: icon-bounce 0.8s ease-out infinite;
	animation: icon-bounce 0.8s ease-out infinite;
}

.about-two_counter-words {
	position: relative;
	font-weight: 800;
	line-height: 80px;
	font-size: var(--font-80);
	color: var(--white-color);
	padding-top: var(--padding-top-40);
}

.about-two_counter-text {
	position: relative;
	line-height: 30px;
	font-weight: 500;
	font-size: var(--font-20);
	color: var(--white-color);
}

.about-two_counter-text span {
	position: relative;
	display: block;
	text-transform: uppercase;
}

/*** 

====================================================================
	Event One
====================================================================

***/

.event-one {
	position: relative;
	z-index: 1;
}

.event-block_one {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.event-block_one-inner {
	position: relative;
	min-height: 75px;
	padding: 30px 25px 25px 0px;
}

.event-block_one-inner::before {
	position: absolute;
	content: '';
	left: 40px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	border-radius: 10px;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}

.event-block_one-content {
	position: relative;
	color: var(--color-five);
	padding-left: var(--padding-left-120);
}

.event-block_one-icon {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 75px;
	height: 75px;
	line-height: 75px;
	text-align: center;
	border-radius: 10px;
	display: inline-block;
	color: var(--white-color);
	font-family: "Flaticon";
	font-size: var(--font-30);
	background-color: var(--main-color);
}

.event-block_one:nth-child(2) .event-block_one-icon {
	background-color: var(--color-three);
}

.event-block_one-icon:after {
	position: absolute;
	content: '';
	left: 8px;
	bottom: -16px;
	border-top: 16px solid var(--color-five);
	border-left: 32px solid transparent;
}

.event-block_one strong {
	display: block;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-18);
	text-transform: uppercase;
	margin-bottom: var(--margin-bottom-10);
}

/*** 

====================================================================
	Counter Two
====================================================================

***/

.counter-two {
	position: relative;
	overflow: hidden;
	margin-top: -95px;
	background-color: var(--color-seven);
}

.counter-two .inner-container {
	position: relative;
	padding: 180px 0px 70px;
}

.counter-two_pattern {
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	bottom: 0px;
	overflow: hidden;
	background-position: center center;
	background-repeat: repeat-x;
}

.counter-two_block {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.counter-two_inner {
	position: relative;
	padding-left: var(--padding-left-90);
}

.counter-two_counter {
	position: relative;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-48);
	line-height: 55px;
}

.counter-two_text {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-18);
}

.counter-two_block-icon {
	position: absolute;
	left: 0px;
	top: 7px;
	line-height: 1em;
	color: var(--color-four);
	font-size: var(--font-60);
	font-family: "Flaticon";
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.counter-two_inner:hover .counter-two_block-icon {
	transform: rotateY(180deg);
}

.counter-two_block:nth-child(2) .counter-two_block-icon {
	color: var(--main-color);
}

.counter-two_block:nth-child(3) .counter-two_block-icon {
	color: var(--color-three);
}

.counter-two_block:nth-child(4) .counter-two_block-icon {
	color: var(--color-six);
}

/*** 

====================================================================
	Event Two
====================================================================

***/

.event-two {
	position: relative;
	background-attachment: fixed;
	background-size: cover;
	padding: 120px 0px 100px;
}

.event-two::before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	opacity: 0.80;
	background-color: var(--color-four);
}

.event-two .event-block_two {
	width: 16.6%;
	float: left;
	padding: 0px 15px;
}

.event-block_two {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.event-block_two-inner {
	position: relative;
	text-align: center;
}

.event-block_two-icon {
	position: relative;
	width: 170px;
	height: 170px;
	display: block;
	margin: 0 auto;
	line-height: 168px;
	text-align: center;
	font-family: "Flaticon";
	color: var(--white-color);
	font-size: var(--font-80);
	border: 2px solid rgba(var(--white-color-rgb), 0.50);
}

.event-block_two-inner:hover .event-block_two-icon {
	animation: icon-bounce 0.8s ease-out;
	-webkit-animation: icon-bounce 0.8s ease-out;
}

.event-block_two-title {
	position: relative;
	line-height: 28px;
	margin: 0 auto;
	margin-top: var(--margin-top-20);
	max-width: 120px;
}

.event-block_two-title a {
	position: relative;
	color: var(--white-color);
}

/*** 

====================================================================
	Team One
====================================================================

***/

.team-one {
	position: relative;
	padding: 110px 0px 80px;
	background-color: var(--color-seven);
}

.team-block_one {
	position: relative;
}

.team-block_one-inner {
	position: relative;
}

.team-block_one-pattern {
	position: absolute;
	left: 10px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-position: left bottom;
	background-size: no-repeat;
}

.team-block_one-image {
	position: relative;
	overflow: hidden;
	border-radius: 20px;
	border: 4px solid var(--main-color);
}

.team-block_one-image img {
	position: relative;
	width: 100%;
	display: block;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.team-block_one-inner:hover .team-block_one-image img {
	opacity: 0.80;
	transform: scale(1.05, 1.05);
}

.team-block_one-content {
	position: relative;
	padding-top: var(--padding-top-25);
	padding-left: var(--padding-left-55);
	padding-bottom: var(--padding-bottom-30);
}

.team-block_one-title {
	position: relative;
	font-weight: 700;
}

.team-block_one-title a {
	position: relative;
	color: var(--color-two);
}

.team-block_one-title a:hover {
	color: var(--color-three);
}

.team-block_one-designation {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-top: var(--margin-top-10);
}

.team-block_one-social-list {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: -60px;
	text-align: center;
	padding: 17px 10px 13px;
	background-color: var(--main-color);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.team-block_one-social-list a {
	position: relative;
	margin: 0px 10px;
	line-height: 1em;
	color: var(--white-color);
	font-size: var(--font-16);
}

.team-block_one-inner:hover .team-block_one-social-list {
	bottom: 0px;
}

/*** 

====================================================================
	Speaker Two
====================================================================

***/

.speakers-two {
	position: relative;
	padding: 110px 0px 70px;
}

.speakers-two .inner-container {
	position: relative;
	padding: 0px 80px;
}

/*** 

====================================================================
	Speaker Tabs Style
====================================================================

***/

.speaker-tabs {
	position: relative;
	margin-top: var(--margin-top-30);
}

.speaker-tabs .tab-btns {
	position: relative;
	text-align: center;
	border: 0;
	justify-content: center;
}

.speaker-tabs .tab-btns .tab-btn .nav-link{
	position: relative;
	line-height: 24px;
	font-weight: 700;
	padding: 0;
	cursor: pointer;
	width: 170px;
	margin: 0px 12px;
	display: inline-block;
	color: var(--color-two);
	font-size: var(--font-18);
	text-transform: uppercase;
	transition: all 500ms ease;
	background-color: var(--color-seven);
	padding-bottom: var(--padding-bottom-15);
	border: 0;
}

.speaker-tabs .tab-btns .tab-btn .nav-link span {
	position: relative;
	display: block;
	font-weight: 700;
	padding: 5px 0px;
	letter-spacing: 1px;
	font-size: var(--font-12);
	text-transform: uppercase;
	color: var(--white-color);
	background-color: var(--color-four);
	margin-bottom: var(--margin-bottom-15);
}

.speaker-tabs .tab-btns .tab-btn .nav-link::before {
	position: absolute;
	content: '';
	left: 50%;
	opacity: 0;
	bottom: 0px;
	margin-left: -7px;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 15px solid var(--color-seven);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.speaker-tabs .tab-btns .tab-btn:nth-child(2) .nav-link span {
	background-color: var(--color-three);
}

.speaker-tabs .tab-btns .tab-btn:nth-child(3) .nav-link span {
	background-color: var(--main-color);
}

.speaker-tabs .tab-btns .tab-btn .nav-link:hover::before,
.speaker-tabs .tab-btns .tab-btn .nav-link.active::before {
	opacity: 1;
	bottom: -15px;
}

.speaker-tabs .tab-btns .tab-btn:last-child {
	margin-right: var(--margin-zero);
}

.speaker-tabs .tabs-content {
	position: relative;
	padding: 40px 0px 0px;
}

.speaker-tabs .tabs-content .tab {
	position: relative;
	display: none;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transform: translateY(80px);
}

.speaker-tabs .tabs-content .tab.active-tab {
	display: block;
	transform: translateY(0px);
}

.leadership-two_block {
	position: relative;
	margin-bottom: var(--margin-bottom-5);
}

.leadership-two_block-inner {
	position: relative;
	padding-left: 340px;
	min-height: 310px;
}

.leadership-two_block-content {
	position: relative;
	top: 45px;
	padding: 35px 60px 35px 30px;
}

.leadership-two_block-content::before {
	position: absolute;
	content: '';
	left: -100px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	border-radius: 0px 200px 200px 0px;
	background-color: var(--color-seven);
}

.leadership-two_block-image {
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 1;
	width: 310px;
	height: 310px;
	padding: 35px 35px;
	border-radius: 50%;
	border: 10px solid var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
	background: #FFC600;
	background: linear-gradient(to right, #FFC600 0%, #ffe801 100%);
}

.leadership-two_block-image img {
	position: relative;
	border-radius: 50%;
	overflow: hidden;
	border: 5px solid var(--white-color);
}

.leadership-two_block-icon {
	position: absolute;
	left: 50%;
	bottom: 10px;
	width: 60px;
	height: 60px;
	line-height: 60px;
	border-radius: 50px;
	text-align: center;
	margin-left: -30px;
	font-family: "Flaticon";
	color: var(--color-two);
	font-size: var(--font-26);
	background-color: var(--white-color);
}

.leadership-two_block-inner:hover .leadership-two_block-icon {
	-webkit-animation: icon-bounce 0.8s ease-out infinite;
	animation: icon-bounce 0.8s ease-out infinite;
}

.leadership-two_block-time {
	position: relative;
	font-weight: 700;
	letter-spacing: 1px;
	font-size: var(--font-14);
	text-transform: uppercase;
	color: var(--color-five);
}

.leadership-two_block-name {
	position: relative;
	font-weight: 700;
	font-size: var(--font-18);
	text-transform: uppercase;
	color: var(--color-four);
	margin-top: var(--margin-top-10);
}

.leadership-two_block-name span {
	position: relative;
	color: var(--color-five);
	text-transform: capitalize;
	font-size: var(--font-14);
}

.leadership-two_block-title {
	position: relative;
	font-weight: 700;
	margin-top: var(--margin-top-10);
}

.leadership-two_block-title a {
	position: relative;
	color: var(--color-two);
}

.leadership-two_block-title a:hover {
	color: var(--color-three);
}

.leadership-two_block-location {
	position: relative;
	font-weight: 600;
	color: var(--color-five);
	font-size: var(--font-14);
	margin-top: var(--margin-top-20);
}

.leadership-two_block-location span {
	position: relative;
	color: var(--color-three);
}

.leadership-two_block.style-two .leadership-two_block-inner {
	padding-right: 340px;
	padding-left: var(--padding-zero);
}

.leadership-two_block.style-two .leadership-two_block-image {
	left: auto;
	right: 0px;
}

.leadership-two_block.style-two .leadership-two_block-content::before {
	left: 0px;
	right: -200px;
	border-radius: 200px 0px 0px 200px;
}

.leadership-two_block.style-two .leadership-two_block-content {
	padding-left: var(--padding-left-80);
}

.leadership-two_block:nth-child(2) .leadership-two_block-image {
	background: #2A00FB;
	background: linear-gradient(to right, #2A00FB 0%, #3100C0 100%);
}

.leadership-two_block:nth-child(3) .leadership-two_block-image {
	background: #E00B78;
	background: linear-gradient(to right, #E00B78 0%, #b0005a 100%);
}

.leadership-two_block:nth-child(4) .leadership-two_block-image {
	background: #080707;
	background: linear-gradient(to right, #080707 0%, #70666b 100%);
}

/*** 

====================================================================
	Faq One
====================================================================

***/

.faq-one {
	position: relative;
	padding: 110px 0px 80px;
	background-size: cover;
	background-position: center center;
}

/*** 

====================================================================
	Accordion Style
====================================================================

***/

.accordion-box {
	position: relative;
	z-index: 1;
}

.accordion-box li{
	margin-bottom: 15px;
}

.accordion-box .block .MuiButtonBase-root{
	position: relative;
	padding: 20px 25px 20px 30px;
	background-color: var(--white-color);
}

.accordion-box .MuiPaper-root,
.accordion-box .MuiPaper-root.Mui-expanded {
	margin-bottom: 15px;
}

.accordion-box .block .MuiAccordionDetails-root{
	padding: 0px 25px 20px 30px;
}

.accordion-box .block:last-child {
	margin-bottom: var(--margin-zero);
}

.accordion-box .block .acc-btn.active {}

.accordion-box .block .acc-btn p{
	position: relative;
	cursor: pointer;
	font-weight: 600;
	color: var(--color-two);
	font-size: var(--font-18);
	transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	width: 100%;
}

.accordion-box .block .MuiAccordionSummary-content {
	padding: 0;
	margin: 0;
}

.accordion-box p {
	line-height: 28px;
	font-size: var(--font-16);
	color: var(--color-five);
	margin-bottom: var(--margin-bottom-10)
}

.accordion-box p:last-child {
	margin-bottom: var(--margin-zero);
}

.accordion-box .block .icon {
	position: absolute;
	right: 0px;
	top: 0px;
	font-size: var(--font-16);
	color: var(--black-color);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.accordion-box .block .icon-outer .icon {
	position: relative;
	right: 0px;
	display: inline-block;
	color: var(--color-fourty);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.accordion-box .block.active-block {
	border-color: var(--color-fourteen);
}

.accordion-box .block .acc-btn.active .icon-outer .icon {
	transform: rotate(180deg);
}

.accordion-box .block .acc-content {
	position: relative;
	display: none;
}

.accordion-box .block .acc-content .content-text {}

.accordion-box .block .acc-content.current {
	display: block;
}

.accordion-box .block .content {
	position: relative;
	padding: 20px 0px 0px 0px;
}

.accordion-box .block .content .text {
	line-height: 28px;
	font-size: var(--font-16);
	color: var(--color-three);
}

.accordion-box.style-two .block {
	border: 1px solid rgba(var(--black-color-rgb), 0.07);
}

/*** 

====================================================================
	Price Two
====================================================================

***/

.price-two {
	position: relative;
	overflow: hidden;
	padding: 110px 0px 80px;
}

.price-two_pattern-one {
	position: absolute;
	left: -540px;
	top: -50px;
	width: 1081px;
	height: 1081px;
	background-repeat: no-repeat;
}

.price-two_pattern-two {
	position: absolute;
	right: -540px;
	top: -50px;
	width: 1081px;
	height: 1081px;
	background-repeat: no-repeat;
}

.price-block_two {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}



.price-block_two-inner::before {
	position: absolute;
	content: '';
	left: -250px;
	top: 30px;
	right: -250px;
	height: 140px;
	transform: rotate(-20deg);
	background-color: var(--color-two);
	border: 2px solid #123454;
}

.price-block_two-color_layer {
	position: absolute;
	content: '';
	left: -250px;
	top: -10px;
	right: 50px;
	height: 150px;
	background-color: var(--main-color);
	transform: rotate(20deg);
	clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
}

.price-block_two-time {
	position: absolute;
	right: 20px;
	top: 20px;
	letter-spacing: 1px;
	font-size: var(--font-18);
	color: var(--white-color);
	text-transform: uppercase;
}

.price-block_two-title {
	position: relative;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-16);
	padding-left: var(--padding-left-50);
}

.price-block_two-date {
	position: relative;
	font-weight: 800;
	line-height: 1em;
	color: var(--color-two);
	font-size: var(--font-72);
	margin-top: var(--margin-top-5);
	padding-left: var(--padding-left-50);
}

.price-block_two-content {
	position: relative;
	margin-top: var(--margin-top-100);
}

.price-block_two-options {
	position: relative;
}

.price-block_two-options li {
	position: relative;
	display: block;
	color: var(--color-five);
	font-size: var(--font-16);
	padding-left: var(--padding-left-30);
	margin-bottom: var(--margin-bottom-25);
}

.price-block_two-options li strong {
	position: relative;
	display: block;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-18);
	margin-bottom: var(--margin-bottom-5);
}

.price-block_two-options li.light {
	opacity: 0.70;
}

.price-block_two-options li:last-child {
	margin-bottom: var(--margin-zero);
}

.price-block_two-options li::before {
	position: absolute;
	content: "\f1a9";
	left: 0px;
	top: 0px;
	font-family: "Flaticon";
}

.price-block_two-button {
	position: relative;
	margin-top: var(--margin-top-40);
}

.price-block_two-charges {
	position: relative;
	font-weight: 600;
	color: var(--color-two);
	font-size: var(--font-14);
	margin-top: var(--margin-top-10);
}

.price-block_two:nth-child(2) .price-block_two-title,
.price-block_two:nth-child(3) .price-block_two-title,
.price-block_two:nth-child(2) .price-block_two-date,
.price-block_two:nth-child(3) .price-block_two-date {
	color: var(--white-color);
}

.price-block_two:nth-child(2) .price-block_two-color_layer {
	background-color: var(--color-three);
}

.price-block_two:nth-child(3) .price-block_two-color_layer {
	background-color: var(--color-four);
}

.price-block_two:nth-child(2) .theme-btn {
	background-color: var(--color-three);
}

.price-block_two:nth-child(2) .btn-style-one .btn-wrap .text-one {
	color: var(--white-color);
}

.price-block_two:nth-child(3) .theme-btn {
	background-color: var(--color-four);
}

.price-block_two:nth-child(3) .btn-style-one .btn-wrap .text-one {
	color: var(--white-color);
}

/*** 

====================================================================
	News Two
====================================================================

***/

.news-two {
	position: relative;
	padding: 100px 0px 80px;
}

.news-block_two {
	position: relative;
	z-index: 2;
	margin-bottom: var(--margin-bottom-30);
}

.news-block_two-inner {
	position: relative;
}

.news-block_two-date {
	position: absolute;
	left: 25px;
	top: 25px;
	z-index: 2;
	font-weight: 600;
	padding: 7px 20px;
	letter-spacing: 1px;
	color: var(--white-color);
	font-size: var(--font-12);
	text-transform: uppercase;
	background-color: var(--color-four);
}

.news-block_two-image {
	position: relative;
	overflow: hidden;
}

.news-block_two-image img:first-child {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	-webkit-transform: translatex(50%) scalex(2);
	transform: translatex(50%) scalex(2);
	opacity: 0;
	-webkit-filter: blur(10px);
	filter: blur(10px);
}

.news-block_two-inner:hover .news-block_two-image img:first-child {
	-webkit-transform: translatex(0) scalex(1);
	transform: translatex(0) scalex(1);
	opacity: 1;
	-webkit-filter: blur(0);
	filter: blur(0);
}

.news-block_two-inner:hover .news-block_two-image img:nth-child(2) {
	-webkit-transform: translatex(-50%) scalex(2);
	transform: translatex(-50%) scalex(2);
	opacity: 0;
	-webkit-filter: blur(10px);
	filter: blur(10px);
}

.news-block_two-image img {
	position: relative;
	width: 100%;
	display: block;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.news-block_two-content {
	position: relative;
	padding: 30px 30px 30px;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.news-block_two-meta {
	position: relative;
}

.news-block_two-meta li {
	position: relative;
	display: inline-block;
	color: var(--color-five);
	text-transform: capitalize;
	padding-left: var(--padding-left-25);
	margin-right: var(--margin-right-20);
}

.news-block_two-meta li .icon {
	position: absolute;
	left: 0px;
	top: 4px;
	line-height: 1em;
	color: var(--main-color);
}

.news-block_two-title {
	position: relative;
	font-weight: 800;
	text-transform: capitalize;
	margin-top: var(--margin-top-10);
}

.news-block_two-title a {
	position: relative;
	color: var(--color-two);
}

.news-block_two-title a:hover {
	color: var(--color-three);
}

.news-block_two-more {
	position: relative;
	font-weight: 700;
	letter-spacing: 1px;
	display: inline-block;
	color: var(--color-three);
	text-transform: capitalize;
	margin-top: var(--margin-top-15);
	padding-right: var(--padding-right-25);
}

.news-block_two-more:hover {
	color: var(--color-two);
}

.news-block_two-more::before {
	position: absolute;
	content: '\f178';
	right: 0px;
	top: 5px;
	line-height: 1em;
	font-family: 'Font Awesome 6 Free';
}

/*** 

====================================================================
	Location one
====================================================================

***/

.location-one {
	position: relative;
	padding: 100px 0px 80px;
}

.location-one_map-column {
	position: relative;
}

.location-one_map-column iframe {
	position: relative;
	width: 100%;
	height: 400px;
}

.location-info_block {
	position: relative;
	margin-bottom: var(--margin-bottom-20);
}

.location-info_block-inner {
	position: relative;
	padding: 35px 40px;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.location-info_block-content {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-16);
	padding-left: var(--padding-left-60);
}

.location-info_block-icon {
	position: absolute;
	left: 0px;
	top: 3px;
	line-height: 1em;
	font-size: var(--font-40);
	color: var(--color-three);
	font-family: "Flaticon";
}

.location-info_block strong {
	position: relative;
	display: block;
	color: var(--color-two);
	text-transform: uppercase;
	font-size: var(--font-18);
	margin-bottom: var(--margin-bottom-5);
}

.location-info_block:nth-child(2) .location-info_block-icon {
	color: var(--color-four);
}

.location-info_block:nth-child(3) .location-info_block-icon {
	color: var(--main-color);
}

/*** 

====================================================================
	Subscribe One
====================================================================

***/

.subscribe-two {
	position: relative;
	overflow: hidden;
	padding: 50px 0px 50px;
	background-color: var(--main-color);
}

.subscribe-two .inner-container {
	position: relative;
}

.subscribe-two_title {
	position: relative;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-16);
	text-transform: capitalize;
}

.subscribe-two_heading {
	position: relative;
	color: var(--color-two);
	margin-top: var(--margin-top-10);
}

/*** 

====================================================================
	Slider Three
====================================================================

***/

.slider-three {
	position: relative;
	overflow: hidden;
}

.slider-three .owl-dots,
.slider-three .owl-nav {
	display: none;
}

.slider-three_slide {
	position: relative;
	overflow: hidden;
	padding: 0px 0px 60px;
}

.slider-three_pattern-one {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	opacity: 0.70;
}

.slider-three_image-layer {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-size: cover;

}

.slider-three_image-column {
	position: relative;
	z-index: 1;
}

.slider-three_image {
	position: relative;
}

.slider-three_image img {
	max-width: inherit !important;
	width: auto !important;
	margin: 0 auto;
}

.slider-three_content {
	position: relative;
	z-index: 1;
}

.slider-three_content-inner {
	position: relative;
	padding-top: 380px;
}

.slider-three_title {
	position: relative;
	font-weight: 600;
	color: var(--main-color);
	font-size: var(--font-16);
}

.slider-three_heading {
	line-height: 80px;
	font-size: var(--font-72);
	color: var(--white-color);
	margin-top: var(--margin-top-15);
}

.slider-three_location {
	position: relative;
	font-size: var(--font-16);
	color: var(--white-color);
	margin-top: var(--margin-top-20);
	margin-bottom: var(--margin-bottom-25);
}

.slider-three_button {
	position: relative;
	margin-top: var(--margin-top-20);
}

.slider-three_timer {
	position: relative;
	z-index: 1;
	left: -35px;
}

.slider-three .time-countdown {
	position: relative;
}

.slider-three .time-countdown .react-countdown .time-section{
	position: relative;
	z-index: 7;
	line-height: 1em;
	font-weight: 600;
	text-align: center;
	border-radius: 0px;
	margin: 0 30px 20px;
	color: var(--white-color);
	text-transform: uppercase;
	font-size: var(--font-20);
	display: inline-block;
}

.slider-three .time-countdown .react-countdown .time-section .time {
	position: relative;
	display: block;
	line-height: 1em;
	font-weight: 800;
	color: var(--white-color);
	font-size: var(--font-60);
	margin-bottom: var(--margin-bottom-10);
}

@media(max-width:767px){
	.slider-three .time-countdown .react-countdown .time-section .time {
		font-size: var(--font-30);
	}

	.slider-three .time-countdown .react-countdown .time-section{ 
		margin: 0 10px 20px;
	}
	.slider-three .time-countdown .react-countdown{ 
		padding-left: 15px;
	}
}

.slider-three .time-countdown .counter-column::before {
	position: absolute;
	content: ':';
	right: -50px;
	top: 5px;
	line-height: 1em;
	font-weight: 800;
	color: var(--white-color);
	font-size: var(--font-60);
}

.slider-three .time-countdown .counter-column:last-child:before {
	display: none;
}

/*** 

====================================================================
	Services One
====================================================================

***/

.services-one {
	position: relative;
	padding: 110px 0px 90px;
}

.services-one_pattern-one {
	position: absolute;
	left: -40px;
	top: 64%;
	width: 112px;
	height: 115px;
	background-repeat: no-repeat;
}

.services-one_pattern-two {
	position: absolute;
	right: 0px;
	top: 5%;
	width: 50%;
	height: 100%;
	background-position: right top;
	background-repeat: no-repeat;
}

.service-block_one {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.service-block_one-inner {
	position: relative;
	text-align: center;
	padding: 45px 40px 60px;
	border: 1px solid var(--color-ten);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	background-color: var(--white-color);
}

.service-block_one-inner::before {
	position: absolute;
	content: '';
	left: -1px;
	top: -1px;
	right: -1px;
	bottom: -1px;
	opacity: 0;
	background-color: var(--color-three);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;

	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
	transition: all 900ms ease;
	-webkit-transform: perspective(400px) rotateY(-90deg);
	-moz-transform: perspective(400px) rotateY(-90deg);
	-ms-transform: perspective(400px) rotateY(-90deg);
	-o-transform: perspective(400px) rotateY(-90deg);
	transform: perspective(400px) rotateY(-90deg);
	-webkit-transform-origin: right;
	-moz-transform-origin: right;
	-ms-transform-origin: right;
	-o-transform-origin: right;
	transform-origin: right;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

.service-block_one-inner:hover::before {
	opacity: 1;
	-webkit-transform: perspective(400px) rotateY(0deg);
	-moz-transform: perspective(400px) rotateY(0deg);
	-ms-transform: perspective(400px) rotateY(0deg);
	-o-transform: perspective(400px) rotateY(0deg);
	transform: perspective(400px) rotateY(0deg);
}

.service-block_one-inner:hover .service-block_one-text,
.service-block_one-inner:hover .service-block_one-heading a {
	color: var(--white-color);
}

.service-block_one-inner:hover .service-block_one-icon {
	color: var(--white-color);
	-webkit-transform: scale(-1) rotate(180deg);
	-moz-transform: scale(-1) rotate(180deg);
	-ms-transform: scale(-1) rotate(180deg);
	-o-transform: scale(-1) rotate(180deg);
	transform: scale(-1) rotate(180deg);
}

.service-block_one-icon {
	position: relative;
	line-height: 1em;
	font-family: "Flaticon";
	font-size: var(--font-60);
	color: var(--color-four);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.service-block_one-heading {
	position: relative;
	margin-top: var(--margin-top-25);
}

.service-block_one-heading a {
	position: relative;
	color: var(--color-two);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.service-block_one-heading a:hover {}

.service-block_one-text {
	position: relative;
	line-height: 28px;
	font-size: var(--font-16);
	color: var(--color-five);
	margin-top: var(--margin-top-15);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.service-block_one-arrow {
	position: absolute;
	left: 50%;
	opacity: 0;
	bottom: -25px;
	width: 50px;
	height: 50px;
	font-weight: 700;
	line-height: 50px;
	text-align: center;
	display: inline-block;
	font-size: var(--font-12);
	font-family: "Flaticon";
	color: var(--color-two);
	background-color: var(--main-color);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transform: scale(0.5, 1);
}

.service-block_one-arrow:hover {
	color: var(--white-color);
	background-color: var(--color-two);
}

.service-block_one-inner:hover .service-block_one-arrow {
	opacity: 1;
	transform: scale(1, 1) translateX(-50%);
}

/*** 

====================================================================
	Business One
====================================================================

***/

.business-one {
	position: relative;
	overflow: hidden;
	padding: 0px 0px 70px;
}

.business-one_content-column {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.business-one_content-inner {
	position: relative;
}

.business-one_content-column .sec-title {
	margin-bottom: var(--margin-bottom-25);
}

.business-one_bold-text {
	position: relative;
	line-height: 30px;
	font-weight: 700;
	color: var(--color-four);
	font-size: var(--font-18);
	margin-bottom: var(--margin-bottom-20);
}

.business-one_text {
	position: relative;
	line-height: 28px;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-bottom: var(--margin-bottom-30);
}

.business-one_feature {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-16);
	padding-left: var(--padding-left-70);
	margin-bottom: var(--margin-bottom-35);
}

.business-one_feature-icon {
	position: absolute;
	left: 0px;
	top: 0px;
	line-height: 1em;
	display: inline-block;
	font-size: var(--font-54);
	color: var(--color-three);
	font-family: "Flaticon";
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.business-one_feature:hover .business-one_feature-icon {
	color: var(--color-four);
	-webkit-transform: scale(-1) rotate(180deg);
	-moz-transform: scale(-1) rotate(180deg);
	-ms-transform: scale(-1) rotate(180deg);
	-o-transform: scale(-1) rotate(180deg);
	transform: scale(-1) rotate(180deg);
}

.business-one_feature strong {
	position: relative;
	display: block;
	color: var(--color-two);
	font-size: var(--font-20);
	margin-bottom: var(--margin-bottom-10);
}

.business-one_image-column {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.business-one_image-inner {
	position: relative;
	padding-bottom: 60px;
	padding-left: var(--padding-left-40);
}

.business-one_image {
	position: relative;
	overflow: hidden;
	display: inline-block;
	border-radius: 0px 80px 0px 0px;
}

.business-one_image-two {
	position: absolute;
	left: 140px;
	bottom: 0px;
	right: -140px;
	border: 5px solid var(--white-color);
}

.business-one_experiance {
	position: absolute;
	right: 0px;
	bottom: 200px;
	z-index: 1;
	width: 240px;
	height: 170px;
	text-align: center;
	padding: 20px 5px 0px;
	background-color: var(--main-color);
	-webkit-animation: icon-bounce 0.8s ease-out infinite;
	animation: icon-bounce 0.8s ease-out infinite;
}

.business-one_counter {
	position: relative;
	font-weight: 800;
	line-height: 1.3em;
	color: var(--color-two);
	font-size: var(--font-48);
	text-transform: capitalize;
}

.business-one_experiance i {
	position: relative;
	display: block;
	font-weight: 700;
	font-style: normal;
	color: var(--color-two);
	font-size: var(--font-16);
	margin-top: var(--margin-top-5);
}

.business-one_experiance strong {
	position: relative;
	display: block;
	font-weight: 800;
	font-style: normal;
	color: var(--color-two);
	font-size: var(--font-24);
	margin-top: var(--margin-top-15);
}

.business-one_pattern-one {
	position: absolute;
	left: 0px;
	top: 50px;
	right: 0px;
	bottom: 0px;
	background-position: center center;
	background-repeat: no-repeat;
}

/*** 

====================================================================
	Counter Three
====================================================================

***/

.counter-three {
	position: relative;
	z-index: 1;
}

.counter-three .inner-container {
	position: relative;
	text-align: center;
	padding: 60px 0px 30px;
	background: #2a00fe;
	border-radius: 0px 150px 150px 150px;
	background: linear-gradient(to top, #2a00fe 0%, #1B1622 100%);
}

.counter-three_block {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.counter-three_inner {
	position: relative;
	display: inline-block;
}

.counter-three_inner::before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	width: 101px;
	height: 103px;
	background: url(../images/background/pattern-22.png) no-repeat;
}

.counter-three_counter {
	position: relative;
	font-weight: 700;
	color: var(--white-color);
	font-size: var(--font-40);
}

.counter-three_text {
	position: relative;
	color: var(--white-color);
	font-size: var(--font-18);
	margin-top: var(--margin-top-5);
}

/*** 

====================================================================
	Speaker Three
====================================================================

***/

.speakers-three {
	position: relative;
	margin-top: -100px;
	padding: 210px 0px 70px;
	background-color: var(--color-eleven);
}

.speakers-three_pattern {
	position: absolute;
	left: -80px;
	top: 100px;
	width: 523px;
	height: 446px;
	z-index: 1;
	background-repeat: no-repeat;
}

.speakers-three_image {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-size: cover;
}


.speaker-block_three {
	position: relative;
	z-index: 1;
	margin-bottom: var(--margin-bottom-40);
}

.speaker-block_three-inner {
	position: relative;
	overflow: hidden;
}

.speaker-block_three-image {
	position: relative;
	border-radius: 20% 0% 30% 0%;
	background-color: var(--color-three);
	border: 4px solid var(--main-color);
}

.speaker-block_three-image img {
	position: relative;
	overflow: hidden;
	width: 100%;
	border-radius: 20% 0% 30% 0%;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.speaker-block_three-inner:hover .speaker-block_three-image img {
	opacity: 0.60;
}

.speaker-block_three-social {
	position: absolute;
	right: 0px;
	bottom: 0px;
	z-index: 2;
}

.speaker-block_three-share {
	position: relative;
	width: 40px;
	height: 40px;
	z-index: 2;
	line-height: 40px;
	text-align: center;
	display: inline-block;
	font-size: var(--font-16);
	color: var(--color-two);
	border-radius: 0px 0px 15px 0px;
	font-family: 'FontAwesome';
	background-color: var(--main-color);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	overflow: hidden;
}

.speaker-block_three-social-list {
	position: absolute;
	left: 0px;
	width: 40px;
	bottom: -200%;
	opacity: 0;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.speaker-block_three-inner:hover .speaker-block_three-social-list {
	bottom: 50px;
	opacity: 1;
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.speaker-block_three-social-list a {
	position: relative;
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	border-radius: 50px;
	display: inline-block;
	font-size: var(--font-16);
	color: var(--black-color);
	background-color: var(--white-color);
	margin-bottom: var(--margin-bottom-5);
}

.speaker-block_three-social-list a.youtube {
	color: #ff0000;
}

.speaker-block_three-social-list a.twitter {
	color: #1da1f2;
}

.speaker-block_three-social-list a.facebook {
	color: #4267b2;
}

.speaker-block_three-social-list a.instagram {
	color: #e73a67;
}

.speaker-block_three-content {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: -80px;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	padding-left: var(--padding-left-30);
}

.speaker-block_three-inner:hover .speaker-block_three-content {
	bottom: 30px;
}

.speaker-block_three-title {
	position: relative;
	font-weight: 700;
}

.speaker-block_three-title a {
	position: relative;
	color: var(--white-color);
}

.speaker-block_three-title a:hover {
	color: var(--main-color);
}

.speaker-block_three-detail {
	position: relative;
	color: var(--white-color);
	font-size: var(--font-16);
	margin-top: var(--margin-top-10);
}

/*** 

====================================================================
	Speaker Four
====================================================================

***/

.speakers-four {
	position: relative;
	padding: 110px 0px 80px;
}

.speakers-four_pattern {
	position: absolute;
	left: -540px;
	top: 250px;
	width: 1081px;
	height: 1081px;
	background-repeat: no-repeat;
}

/*** 

====================================================================
	Speaker Tabs Two
====================================================================

***/

.speaker-tabs_two {
	position: relative;
	margin-top: -190px;
}

.speaker-tabs_two .tab-btns {
	position: relative;
	text-align: right;
	justify-content: flex-end;
	border: 0;
}

@media(max-width:1123px){
	.speaker-tabs_two .tab-btns {
		justify-content: flex-start;
	}
}

.speaker-tabs_two .tab-btns .tab-btn .nav-link{
	position: relative;
	line-height: 24px;
	font-weight: 800;
	cursor: pointer;
	width: 170px;
	margin: 0px 12px;
	text-align: left;
	display: inline-block;
	color: var(--color-two);
	font-size: var(--font-18);
	text-transform: uppercase;
	transition: all 500ms ease;
	border-radius: 0px 20px 20px 0px;
	padding-left: var(--padding-left-55);
	background-color: var(--color-seven);
	padding-bottom: var(--padding-bottom-15);
	overflow: hidden;
    border: 0;
}

.speaker-tabs_two .tab-btns .tab-btn .nav-link span {
	position: absolute;
	left: -56px;
	top: 55px;
	display: block;
	font-weight: 700;
	padding: 5px 46px;
	letter-spacing: 1px;
	font-size: var(--font-12);
	text-transform: uppercase;
	color: var(--white-color);
	transform: rotate(-90deg);
	background-color: var(--color-four);
	margin-bottom: var(--margin-bottom-15);
}

.speaker-tabs_two .tab-btns .tab-btn .nav-link strong {
	position: relative;
	display: block;
	font-weight: 800;
	line-height: 1em;
	color: var(--main-color);
	font-size: var(--font-72);
}

.speaker-tabs_two .tab-btns .tab-btn .nav-link i {
	position: relative;
	font-style: normal;
	color: var(--color-three);
}

.speaker-tabs_two .tab-btns .tab-btn .nav-link::before {
	position: absolute;
	content: '';
	left: 50%;
	opacity: 0;
	bottom: 0px;
	margin-left: -7px;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 15px solid var(--color-seven);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.speaker-tabs_two .tab-btns .tab-btn:nth-child(2) .nav-link span {
	background-color: var(--color-three);
}

.speaker-tabs_two .tab-btns .tab-btn:nth-child(3) .nav-link span {
	background-color: var(--main-color);
}

.speaker-tabs_two .tab-btns .tab-btn .nav-link:hover::before,
.speaker-tabs_two .tab-btns .tab-btn .nav-link.active::before {
	opacity: 1;
	bottom: -15px;
}

.speaker-tabs_two .tab-btns .tab-btn:last-child .nav-link{
	margin-right: var(--margin-zero);
}

.speaker-tabs_two .tabs-content {
	position: relative;
	padding: 60px 90px 0px;
}

.speaker-tabs_two .tabs-content .tab {
	position: relative;
	display: none;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transform: translateY(80px);
}

.speaker-tabs_two .tabs-content .tab.active-tab {
	display: block;
	transform: translateY(0px);
}

/* LeaderShip Three Block */

.leadership-three_block {
	position: relative;
	margin-bottom: var(--margin-bottom-40);
}

.leadership-three_block-inner {
	position: relative;
}

.leadership-three_block-content {
	position: relative;
	min-height: 210px;
	padding-left: 260px;
	overflow: hidden;
	border-radius: 105px 100px 100px 0px;
	padding-top: var(--padding-top-35);
	background-color: var(--color-seven);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.leadership-three_block-inner:hover .leadership-three_block-content {
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.leadership-three_block-image {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 210px;
	height: 210px;
	border-radius: 50% 50% 50% 0%;
	border: 8px solid var(--color-four);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.leadership-three_block-inner:hover .leadership-three_block-image {
	border-color: var(--main-color);
}

.leadership-three_block-image img {
	position: relative;
	overflow: hidden;
	border-radius: 50% 50% 50% 0%;
}

.leadership-three_block-icon {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 100px;
	height: 100px;
	line-height: 100px;
	text-align: center;
	font-size: var(--font-44);
	color: var(--color-two);
	font-family: "Flaticon";
	border-radius: 50px 50px 0px 50px;
	background-color: var(--main-color);
}

.leadership-three_block-name {
	position: relative;
	font-weight: 700;
	color: var(--color-four);
	font-size: var(--font-20);
	margin-top: var(--margin-top-15);
}

.leadership-three_block-name span {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-14);
}

.leadership-three_block-title a:hover {
	color: var(--color-three);
}

.leadership-three_block-title {
	position: relative;
	font-weight: 800;
	margin-top: var(--margin-top-10);
}

.leadership-three_block-title a {
	position: relative;
	color: var(--color-two);
}

.leadership-three_block-title a:hover {
	color: var(--color-three);
}

.leadership-three_block-location {
	position: relative;
	font-weight: 600;
	color: var(--color-five);
	font-size: var(--font-14);
	margin-top: var(--margin-top-15);
}

.leadership-three_block-location span {
	position: relative;
	color: var(--color-three);
}

.leadership-three_block-timing {
	position: absolute;
	right: 50px;
	bottom: 0px;
	font-weight: 700;
	color: var(--color-five);
	font-size: var(--font-16);
	padding: 15px 80px 15px 30px;
	background-color: var(--color-ten);
	border-radius: 50px 0px 0px 50px;
}

/*** 

====================================================================
	Testimonial One
====================================================================

***/

.testimonial-one {
	position: relative;
	padding: 110px 0px 80px;
}

.testimonial-one .owl-dots {
	display: none;
}

.testimonial-one .owl-nav {
	position: absolute;
	right: 0px;
	top: -100px;
}

.testimonial-one .owl-nav .owl-prev,
.testimonial-one .owl-nav .owl-next {
	position: relative;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	border-radius: 50px;
	display: inline-block;
	color: var(--color-two);
	font-size: var(--font-18);
	margin-left: var(--margin-left-15);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.testimonial-one .owl-nav .owl-prev:hover,
.testimonial-one .owl-nav .owl-next:hover {
	color: var(--white-color);
	background-color: var(--color-three);
}

.testimonial-one_pattern {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-size: cover;
}

.testimonial-block_one {
	position: relative;
}

.testimonial-block_one-inner {
	position: relative;
}

.testimonial-block_one-content {
	position: relative;
	padding: 40px 40px;
	border-radius: 0px 25px 25px 0px;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.testimonial-block_one-text {
	position: relative;
	line-height: 34px;
	font-size: var(--font-18);
	color: var(--color-five);
}

.testimonial-block_one-rating {
	position: relative;
	color: var(--main-color);
	font-size: var(--font-16);
	margin-top: var(--margin-top-15);
	margin-left: var(--margin-left-150);
}

.testimonial-block_one-quote {
	position: absolute;
	right: 40px;
	bottom: 30px;
	opacity: 0.10;
	line-height: 1em;
	font-family: "Flaticon";
	color: var(--color-two);
	font-size: var(--font-50);
}

.testimonial-block_one-lower {
	position: relative;
	margin-top: -50px;
	z-index: 1;
	color: var(--color-five);
	font-size: var(--font-14);
	margin-left: var(--margin-left-50);
	padding-top: var(--padding-top-70);
	padding-left: var(--padding-left-140);
}

.testimonial-block_one-lower::before {
	position: absolute;
	content: '';
	left: -50px;
	top: 50px;
	border-top: 70px solid var(--main-color);
	border-left: 50px solid transparent;
}

.testimonial-block_one-author {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 120px;
	height: 120px;
	overflow: hidden;
	border-radius: 0px 30px 0px 0px;
}

.testimonial-block_one-lower strong {
	position: relative;
	display: block;
	font-weight: 800;
	font-size: var(--font-24);
	color: var(--color-four);
	margin-bottom: var(--margin-bottom-10);
}

.testimonial-one .slick-arrow {
	background: #fff;
	width: 50px;
	height: 50px;
	line-height: 65px;
	border: 1px solid #b7b7b7;
	color: #6e6e6e;
	padding: 0;
	font-size: 0px;
	margin: 0;
	border-radius: 50%;
	transition: all 0.3s;
	z-index: 1;
  }

  @media (max-width: 575px) {
	.testimonial-one .slick-arrow {
	  display: none !important;
	}

	.about-one_block-inner {
		position: relative;
		color: var(--color-five);
		font-size: var(--font-16);
		padding-left: var(--padding-left-65);
		width: 350px;
	}
  }

  .testimonial-one .slick-next {
	position: absolute;
	top: -100px;
	right: 0;
  }
  .testimonial-one .slick-next:before {
	font-family: "themify";
	content: "";
	color: var(--color-three);;
  }
  .testimonial-one .slick-prev {
	position: absolute;
	top: -100px;
	left: auto;
	right: 70px;
  }
  .testimonial-one .slick-prev:after {
	left: -3px;
	bottom: -3px;
	border-right: 0 !important;
	border-left: 3px solid var(--color-three);;
	border-bottom: 3px solid var(--color-three);;
  }
  .testimonial-one .slick-prev:before {
	font-family: "themify";
	content: "";
	color: var(--color-three);;
  }
  .testimonial-one .owl-item {
	padding: 10px;
  }
  .testimonial-one .slick-slide {
	padding: 10px;
  }

/*** 

====================================================================
	Price Three
====================================================================

***/

.price-three {
	position: relative;
	padding: 110px 0px 80px;
	background-size: cover;
	background-attachment: fixed;
}

.price-block_three {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.price-block_three-inner {
	position: relative;
	padding: 50px 55px 70px;
}

.price-block_three-pattern {
	position: absolute;
	right: 0px;
	top: 0px;
	left: 0px;
	bottom: 0px;
	background-repeat: no-repeat;
}

.price-block_three-title {
	position: relative;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-14);
}

.price-block_three-price {
	position: relative;
	font-weight: 700;
	line-height: 1em;
	color: var(--color-four);
	font-size: var(--font-72);
	margin-top: var(--margin-top-10);
}

.price-block_three-price span {
	position: relative;
	letter-spacing: 1px;
	font-size: var(--font-16);
	text-transform: uppercase;
	color: var(--color-five);
}

.price-block_three-options {
	position: relative;
	margin-top: var(--margin-top-10);
}

.price-block_three-options li {
	position: relative;
	display: block;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-bottom: var(--margin-bottom-20);
}

.price-block_three-options li strong {
	position: relative;
	display: block;
	font-weight: 700;
	color: var(--color-two);
	margin-bottom: var(--margin-bottom-5);
}

.price-block_three-options li.light {
	opacity: 0.70;
}

.price-block_three-options li:last-child {
	margin-bottom: var(--margin-zero);
}

.price-block_three-button {
	position: relative;
	margin-top: var(--margin-top-30);
}

.price-block_three-charges {
	position: relative;
	font-weight: 600;
	color: var(--color-two);
	font-size: var(--font-14);
	margin-top: var(--margin-top-20);
}

.price-block_three:nth-child(2) .price-block_three-price {
	color: var(--color-three);
}

.price-block_three:nth-child(2) .price-block_three-button .theme-btn {
	background-color: var(--color-three);
}

.price-block_three:nth-child(3) .price-block_three-price {
	color: var(--main-color);
}

.price-block_three:nth-child(3) .price-block_three-button .theme-btn {
	background-color: var(--main-color);
}

/*** 

====================================================================
	News Three
====================================================================

***/

.news-three {
	position: relative;
	padding: 100px 0px 80px;
}

.news-block_three {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.news-block_three-inner {
	position: relative;
}

.news-block_three-image {
	position: relative;
	overflow: hidden;
}

.news-block_three-image img:first-child {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	-webkit-transform: translatex(50%) scalex(2);
	transform: translatex(50%) scalex(2);
	opacity: 0;
	-webkit-filter: blur(10px);
	filter: blur(10px);
}

.news-block_three-inner:hover .news-block_three-image img:first-child {
	-webkit-transform: translatex(0) scalex(1);
	transform: translatex(0) scalex(1);
	opacity: 1;
	-webkit-filter: blur(0);
	filter: blur(0);
}

.news-block_three-inner:hover .news-block_three-image img:nth-child(2) {
	-webkit-transform: translatex(-50%) scalex(2);
	transform: translatex(-50%) scalex(2);
	opacity: 0;
	-webkit-filter: blur(10px);
	filter: blur(10px);
}

.news-block_three-image img {
	position: relative;
	width: 100%;
	display: block;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.news-block_three-post {
	position: absolute;
	left: 15px;
	top: 15px;
	z-index: 3;
	padding: 9px 15px 9px 7px;
	border-radius: 50px;
}

.news-block_three-post:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	width: 40px;
	height: 42px;
	border-radius: 50px;
	background-color: var(--white-color);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.news-block_three-inner:hover .news-block_three-post:before {
	width: 100%;
}

.news-block_three-post-inner {
	position: relative;
	font-weight: 600;
	font-size: var(--font-12);
	text-transform: uppercase;
	color: var(--color-fourteen);
	padding-left: var(--padding-left-40);
}

.news-block_three-post-inner span {
	color: var(--main-color);
}

.news-block_three-post-image {
	position: absolute;
	left: 1px;
	top: 0px;
	width: 24px;
	height: 24px;
}

.news-block_three-post-image:before {
	position: absolute;
	content: '';
	left: -3px;
	top: -3px;
	right: -3px;
	bottom: -3px;
	border-radius: 50px;
	border: 1px solid var(--main-color);
}

.news-block_three-post-image img {
	position: relative;
	border-radius: 50%;
	overflow: hidden;
}

.news-block_three-content {
	position: relative;
	z-index: 2;
	margin-top: -100px;
	padding: 25px 30px 25px;
	margin-left: var(--margin-left-20);
	margin-right: var(--margin-right-20);
	background-color: var(--white-color);
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09);
}

.news-block_three-meta {
	position: relative;
}

.news-block_three-meta li {
	position: relative;
	display: inline-block;
	font-size: var(--font-14);
	padding-left: var(--padding-left-25);
	margin-right: var(--margin-right-20);
	color: rgba(var(--black-color-rgb), 0.80);
}

.news-block_three-meta li .icon {
	position: absolute;
	left: 0px;
	top: 4px;
	font-weight: 800;
	color: var(--color-four);
}

.news-block_three-meta li:last-child {
	margin-right: var(--margin-zero);
}

.news-block_three-heading {
	font-weight: 700;
	line-height: 28px;
	margin-top: var(--margin-top-15);
}

.news-block_three-heading a {
	color: var(--color-two);
}

.news-block_three-heading a:hover {
	color: var(--color-three);
}

.news-block_three-more {
	position: relative;
	font-weight: 700;
	letter-spacing: 1px;
	display: inline-block;
	font-size: var(--font-14);
	color: var(--black-color);
	text-transform: uppercase;
	margin-top: var(--margin-top-15);
	padding-right: var(--padding-right-25);
	padding-bottom: var(--padding-bottom-5);
	border-bottom: 1px solid var(--black-color);
}

.news-block_three-more::before {
	position: absolute;
	content: '\f178';
	right: 0px;
	top: 5px;
	line-height: 1em;
	font-weight: 800;
	font-family: 'FontAwesome';
}

.news-block_three-more:hover {
	color: var(--color-three);
}

/*** 

====================================================================
	Clients Two
====================================================================

***/

.clients-two {
	position: relative;
	overflow: hidden;
	padding-bottom: var(--padding-bottom-100);
}

.clients-two.style-two {
	padding-top: var(--padding-top-100);
}

.clients-two .owl-dots,
.clients-two .owl-nav {
	display: none;
}

.clients-two .clients-two_column {
	margin: 0px 15px;
}

clients-two_column {
	position: relative;
}

.clients-two_column a {
	position: relative;
	display: block;
	width: 270px;
	border: 1px solid rgba(var(--black-color-rgb), 0.07);
}

.clients-two_column a:hover {
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}


.clients-two_slide {
	-webkit-animation: Mover 20s linear infinite;
	animation: Mover 20s linear infinite;
}

@keyframes Mover {
	from {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	to {
		-webkit-transform: translateX(-90%);
		transform: translateX(-90%);
	}
  }

/*** 

====================================================================
	Gallery Two
====================================================================

***/

.gallery-two {
	position: relative;
	margin-bottom: -8px;
}

.gallery-two .owl-dots,
.gallery-two .owl-nav {
	display: none;
}

/*** 

====================================================================
	Page Title
====================================================================

***/

.page-title {
	position: relative;
	padding: 300px 0px 120px;
	background-attachment: fixed;
	background-size: cover;
}

.page-title .left-box {
	position: relative;
}

.page-title::before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	opacity: 0.80;
	z-index: 1;
	background: url(../images/background/12.jpg) no-repeat;
}

.page-title_heading {
	position: relative;
	z-index: 1;
	letter-spacing: 1px;
	color: var(--white-color);
	text-transform: uppercase;
	max-width: 850px;
}

.page-title_big {
	opacity: 0.90;
	display: block;
	font-weight: 800;
	color: var(--main-color);
	font-size: var(--font-100);
	text-transform: uppercase;
}

.bread-crumb {
	position: relative;
	z-index: 1;
}

.bread-crumb li {
	position: relative;
	font-weight: 800;
	letter-spacing: 1px;
	display: inline-block;
	color: var(--white-color);
	font-size: var(--font-16);
	text-transform: uppercase;
	padding-left: var(--padding-left-20);
}

.bread-crumb li:first-child {
	padding-left: var(--padding-zero);
}

.bread-crumb li a {
	position: relative;
	color: var(--white-color);
}

.bread-crumb li a:hover {
	color: var(--main-color);
}

.bread-crumb li::before {
	position: absolute;
	content: ':';
	right: -15px;
	top: -1px;
	color: var(--white-color);
}

.bread-crumb li:last-child::before {
	display: none;
}

/*** 

====================================================================
	Faq Two
====================================================================

***/

.faq-two {
	position: relative;
	padding: 110px 0px 110px;
}

.faq-two_form-box {
	position: relative;
	padding: 60px 50px 50px;
}

.faq-two_form-title {
	position: relative;
	color: var(--white-color);
	margin-bottom: var(--margin-bottom-25);
}

/*** 

====================================================================
	Faq Form
====================================================================

***/

.faq-form .form-group {
	margin-bottom: var(--margin-bottom-25);
}

.faq-form .form-group:last-child {
	margin-bottom: 0px;
}

.faq-form .form-group input[type="text"],
.faq-form .form-group input[type="password"],
.faq-form .form-group input[type="tel"],
.faq-form .form-group input[type="email"] {
	position: relative;
	display: block;
	width: 100%;
	line-height: 28px;
	padding: 10px 25px;
	height: 60px;
	border-radius: 0px;
	font-size: var(--font-16);
	color: var(--color-two);
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	background-color: var(--white-color);
}

.faq-form .form-group textarea::-webkit-input-placeholder,
.faq-form .form-group input::-webkit-input-placeholder {
	color: var(--color-sixteen)
}

.faq-form .form-group input[type="text"]:focus,
.faq-form .form-group input[type="password"]:focus,
.faq-form .form-group input[type="tel"]:focus,
.faq-form .form-group input[type="email"]:focus,
.faq-form .form-group select:focus,
.faq-form .form-group textarea:focus {
	border-color: var(--black-color);
	background-color: var(--white-color);
}

.faq-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 24px;
	padding: 15px 25px 25px;
	font-size: var(--font-16);
	color: var(--color-two);
	height: 160px;
	resize: none;
	border-radius: 0px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	background-color: var(--white-color);
}

.faq-form .theme-btn {
	cursor: pointer;
	font-weight: 600;
	border-radius: 0px;
	padding: 18px 40px;
	letter-spacing: 1px;
	font-size: var(--font-16);
	text-transform: uppercase;
	color: var(--black-color);
	background-color: var(--white-color);
}

/*** 

====================================================================
	Gallery Three
====================================================================

***/

.gallery-three {
	position: relative;
	padding: 110px 0px 110px;
}

.gallery-three .gallery-one_block {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

/*** 

====================================================================
	Testimonial Three
====================================================================

***/

.testimonial-two {
	position: relative;
	padding: 110px 0px 80px;
}

.testimonial-two .testimonial-block_one {
	position: relative;
	margin-bottom: var(--margin-bottom-35);
}

/*** 

====================================================================
	Styled Pagination
====================================================================

***/

.testimonial-two .styled-pagination {
	margin-top: var(--margin-top-20);
}

.styled-pagination {
	position: relative;
}

.styled-pagination li {
	position: relative;
	margin: 0px 5px 10px;
	display: inline-block;
}

.styled-pagination li a {
	position: relative;
	display: inline-block;
	line-height: 48px;
	height: 50px;
	width: 50px;
	min-width: 50px;
	font-weight: 400;
	border-radius: 50px;
	text-align: center;
	text-transform: capitalize;
	transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	font-size: var(--font-16);
	color: var(--black-color);
	border: 1px solid rgba(var(--black-color-rgb), 0.08);
}

.styled-pagination li.prev a,
.styled-pagination li.next a {
	line-height: 45px;
	top: 1px;
	font-family: "Flaticon";
}

.styled-pagination li a:hover,
.styled-pagination li a.active {
	color: var(--white-color);
	border-color: var(--black-color);
	background-color: var(--black-color);
}




/*** 

====================================================================
			Coming Soon
====================================================================

***/

.coming-soon {
	position: fixed;
	width: 100%;
	height: 100%;
	display: block;
	overflow-y: auto;
	background-repeat: no-repeat;
	background-position: center right;
	background-size: cover;
	text-align: center;
}

.coming-soon .content {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: table;
	vertical-align: middle;
}

.coming-soon .content:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: var(--black-color);
	content: "";
	opacity: .50;
}

.coming-soon .content .content-inner {
	position: relative;
	padding: 90px 15px;
	display: table-cell;
	vertical-align: middle;
}

.coming-soon .content .content-inner .logo {
	position: relative;
	margin-bottom: var(--margin-bottom-20);
}

.coming-soon .content .content-inner h2 {
	color: var(--white-color);
	margin-bottom: var(--margin-bottom-200);
}

.time-counter {
	position: relative;
}

.time-counter .time-countdown {
	position: relative;
	margin-bottom: var(--margin-bottom-150);
}

.time-counter .time-countdown .time-section{
	position: relative;
	display: inline-block;
	width: 180px;
	height: 180px;
	line-height: 1em;
	color: var(--white-color);
	text-transform: uppercase;
	font-size: var(--font-20);
	font-weight: 600;
	text-align: center;
	z-index: 7;
	border-radius: 0px;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
	background-color: rgba(0, 0, 0, .80);
	margin: 0 15px 20px;
}

.time-counter .time-countdown .time-section .time{
	position: relative;
	display: block;
	font-size: var(--font-70);
	line-height: 30px;
	padding: 60px 0px 30px;
	color: var(--white-color);
	font-weight: 600;
}

.coming-soon .emailed-form {
	position: relative;
	margin-bottom: var(--margin-bottom-40);
}

.coming-soon .emailed-form h3 {
	color: var(--white-color);
	margin-bottom: var(--margin-bottom-20);
}

.coming-soon .emailed-form .text {
	display: block;
	color: var(--white-color);
	font-size: var(--font-18);
	margin-bottom: var(--margin-bottom-40);
}

.coming-soon .emailed-form .form-group {
	position: relative;
	display: block;
	max-width: 540px;
	width: 100%;
	margin: 0 auto;
}

.coming-soon .emailed-form .form-group input[type="text"],
.coming-soon .emailed-form .form-group input[type="tel"],
.coming-soon .emailed-form .form-group input[type="email"],
.coming-soon .emailed-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 28px;
	height: 60px;
	color: var(--black-color);
	font-size: var(--font-16);
	overflow: hidden;
	padding: 15px 50px 15px 20px;
	background: var(--white-color);
	border: 1px solid #f1f1f1;
	box-shadow: 0 0 13px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.coming-soon .emailed-form .form-group input:focus,
.coming-soon .emailed-form .form-group select:focus,
.coming-soon .emailed-form .form-group textarea:focus {
	border-color: var(--main-color);
	;
}

.coming-soon .emailed-form .form-group input[type="submit"],
.coming-soon .emailed-form button {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 60px;
	height: 60px;
	line-height: 40px;
	font-size: var(--font-18);
	color: var(--main-color);
	background-color: transparent;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.coming-soon .emailed-form .form-group input[type="submit"]:hover,
.coming-soon .emailed-form button:hover {
	color: var(--main-color-two);
}

.coming-soon .copyright-text {
	position: relative;
	font-size: var(--font-18);
	line-height: 26px;
	color: var(--white-color);
	font-weight: 400;
}

.coming-soon .copyright-text a {
	color: var(--main-color);
}

.coming-soon .copyright-text a:hover {
	text-decoration: underline;
}

.clock-wrapper {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	width: 250px;
	height: 250px;
	margin: auto;
	margin-top: -145px;
	border-radius: 50%;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
	background-color: rgba(0, 0, 0, .40);
	transform: scale(2);
}

.clock-base {
	width: 250px;
	height: 250px;
	border-radius: 50%;
}

.click-indicator {
	position: absolute;
	z-index: 1;
	top: 10px;
	left: 10px;
	width: 230px;
	height: 230px;
}

.click-indicator div {
	position: absolute;
	width: 2px;
	height: 4px;
	margin: 113px 114px;
}

.click-indicator div>span {
	height: 5px;
	width: 5px;
	background-color: var(--white-color);
	display: inline-block;
	border-radius: 50%;
	margin-left: -1px;
	margin-top: -1px;
}

.click-indicator div:nth-child(1) {
	transform: rotate(30deg) translateY(-120px);
}

.click-indicator div:nth-child(2) {
	transform: rotate(60deg) translateY(-120px);
}

.click-indicator div:nth-child(3) {
	transform: rotate(90deg) translateY(-120px);
	background-color: var(--main-color);
}

.click-indicator div:nth-child(4) {
	transform: rotate(120deg) translateY(-120px);
}

.click-indicator div:nth-child(5) {
	transform: rotate(150deg) translateY(-120px);
}

.click-indicator div:nth-child(6) {
	transform: rotate(180deg) translateY(-120px);
	background-color: var(--main-color);
}

.click-indicator div:nth-child(7) {
	transform: rotate(210deg) translateY(-120px);
}

.click-indicator div:nth-child(8) {
	transform: rotate(240deg) translateY(-120px);
}

.click-indicator div:nth-child(9) {
	transform: rotate(270deg) translateY(-120px);
	background-color: var(--main-color);
}

.click-indicator div:nth-child(10) {
	transform: rotate(300deg) translateY(-120px);
}

.click-indicator div:nth-child(11) {
	transform: rotate(330deg) translateY(-120px);
}

.click-indicator div:nth-child(12) {
	transform: rotate(360deg) translateY(-120px);
	background-color: var(--main-color);
}

.clock-hour {
	position: absolute;
	z-index: 2;
	top: 75px;
	left: 123px;
	width: 4px;
	height: 65px;
	background-color: var(--main-color);
	border-radius: 2px;
	box-shadow: 0 0 2px rgba(0, 0, 0, .2);
	transform-origin: 2px 50px;
	transition: .5s;
	-webkit-animation: rotate-hour 43200s linear infinite;
	-moz-animation: rotate-hour 43200s linear infinite;
}

.clock-minute {
	position: absolute;
	z-index: 3;
	top: 55px;
	left: 123px;
	width: 4px;
	height: 85px;
	background-color: var(--main-color);
	border-radius: 2px;
	box-shadow: 0 0 2px rgba(0, 0, 0, .2);
	transform-origin: 2px 70px;
	transition: .5s;
	-webkit-animation: rotate-minute 3600s linear infinite;
	-moz-animation: rotate-minute 3600s linear infinite;
}

.clock-second {
	position: absolute;
	z-index: 4;
	top: 15px;
	left: 124px;
	width: 2px;
	height: 130px;
	background-color: var(--white-color);
	opacity: .9;
	box-shadow: 0 0 2px rgba(0, 0, 0, .2);
	transform-origin: 1px 110px;
	transition: .5s;
	-webkit-animation: rotate-second 60s linear infinite;
	-moz-animation: rotate-second 60s linear infinite;
}

.clock-second:after {
	content: "";
	display: block;
	position: absolute;
	left: -3px;
	bottom: 16px;
	width: 8px;
	height: 8px;
	background-color: var(--text-color);
	border: solid 2px var(--text-color);
	border-radius: 50%;
	box-shadow: 0 0 3px rgba(0, 0, 0, .2);
}

.clock-center {
	position: absolute;
	z-index: 1;
	width: 150px;
	height: 150px;
	top: 50px;
	left: 50px;
	box-shadow: 0 0 3px rgba(0, 0, 0, .2);
	border-radius: 50%;
}

.clock-center:after {
	content: "";
	display: block;
	width: 20px;
	height: 20px;
	margin: 65px;
	background-color: var(--text-color);
	border-radius: 50%;
}

.coming-soon .social-box {
	margin-top: var(--margin-top-10);
}

.coming-soon .social-box li {
	position: relative;
	display: inline-block;
	margin-right: var(--margin-right-10);
}

.coming-soon .social-box li a {
	position: relative;
	color: var(--white-color);
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	display: inline-block;
	border: 1px solid var(--white-color);
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.coming-soon .social-box li a:hover {
	background-color: var(--main-color);
	border-color: var(--main-color);
	color: var(--white-color);
}

/*** 

====================================================================
	Privacy Section
====================================================================

***/

.privacy-section {
	position: relative;
	padding: 110px 0px 80px;
}

.privacy-section .pattern-layer {
	position: absolute;
	top: 50px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: right top;
}

.privacy-section .privacy-content {
	position: relative;
	padding-bottom: 15px;
	margin-bottom: 25px;
	border-bottom: 1px solid #e2e2e2;
}

.privacy-section .privacy-content:last-child {
	padding-bottom: 0px;
	margin-bottom: 0px;
	border: none;
}

.privacy-section h3 {
	color: var(--main-color-two);
	margin-bottom: var(--margin-bottom-20);
}

.privacy-section p {
	margin-bottom: var(--margin-bottom-15);
}

.privacy-section .date {
	position: relative;
	font-weight: 500;
	color: var(--main-color);
	font-size: var(--font-18);
	margin-bottom: var(--margin-bottom-15);
}

.privacy-section h4 {
	color: var(--main-color-two);
	margin-bottom: var(--margin-bottom-20);
}

.privacy-section .privacy-list {
	position: relative;
	margin-left: var(--margin-left-20);
}

.privacy-section .privacy-list li {
	position: relative;
	font-weight: 400;
	padding-left: 26px;
	line-height: 1.7em;
	font-size: var(--font-18);
	color: var(--main-color-two);
	margin-bottom: var(--margin-bottom-10);
}

.privacy-section .privacy-list li:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 10px;
	width: 7px;
	height: 7px;
	border-radius: 50px;
	background-color: var(--main-color-two);
}

/*** 

====================================================================
	Speaker Detail
====================================================================

***/

.speaker-detail {
	position: relative;
	padding: 110px 0px 100px;
}

.speaker-detail_author-column {
	position: relative;
}

.speaker-detail_author-image {
	position: relative;
	overflow: hidden;
	border-radius: 20px;
	border: 4px solid var(--main-color);
}

.speaker-detail_author-image img {
	position: relative;
	width: 100%;
	display: block;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.speaker-detail_author-inner {
	position: relative;
}

.speaker-detail_author-pattern {
	position: absolute;
	left: 10px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-position: left bottom;
	background-size: no-repeat;
}

.speaker-detail_author-content {
	position: relative;
	padding-top: var(--padding-top-25);
	padding-left: var(--padding-left-55);
	padding-bottom: var(--padding-bottom-30);
}

.speaker-detail_author-column .speaker-detail_title {
	position: relative;
	font-weight: 700;
	color: var(--white-color);
}

.speaker-detail_title a {
	position: relative;
	color: var(--white-color);
}

.speaker-detail_designation {
	position: relative;
	color: var(--white-color);
	margin-top: var(--margin-top-5);
}

.speaker-detail_social-list {
	position: absolute;
	right: 30px;
	bottom: 0px;
	width: 48px;
	background-color: var(--main-color);
}

.speaker-detail_social-list a {
	position: relative;
	width: 48px;
	height: 48px;
	display: block;
	line-height: 48px;
	text-align: center;
	color: var(--color-two);
}

.speaker-detail_social-list a:hover {
	color: var(--color-two);
	background-color: var(--white-color);
}

.speaker-detail_title {
	position: relative;
	font-weight: 700;
	color: var(--color-two);
	margin-bottom: var(--margin-bottom-15);
}

.speaker-detail_subtitle {
	margin-bottom: var(--margin-bottom-15);
}

.speaker-detail_info {
	position: relative;
}

.speaker-detail_info li {
	position: relative;
	padding-left: 120px;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-bottom: var(--margin-bottom-15);
}

.speaker-detail_info li span {
	position: absolute;
	left: 0px;
	font-weight: 700;
	color: var(--color-two);
}

.event-block_three {
	position: relative
}

.event-block_three-inner {
	position: relative;
	min-height: 235px;
	padding-left: 275px;
	padding-top: var(--padding-top-25);
}

.event-block_three-room {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 235px;
	height: 235px;
	text-align: center;
	background: #e00d78;
	padding-top: var(--padding-top-40);
	background: linear-gradient(to top, #e00d78 0%, #1C171A 100%);
}

.event-block_three-icon {
	position: relative;
	line-height: 1em;
	display: block;
	color: var(--white-color);
	font-size: var(--font-70);
	font-family: "Flaticon";
}

.event-block_three-room_number {
	position: relative;
	font-weight: 700;
	color: var(--white-color);
	font-size: var(--font-24);
	margin-top: var(--margin-top-25);
}

.event-block_three-room_number span {
	position: relative;
	display: block;
	font-weight: 400;
	font-size: var(--font-16);
	margin-top: var(--margin-top-15);
}

.event-block_three-date {
	position: relative;
	font-weight: 600;
	color: var(--color-five);
	font-size: var(--font-18);
	text-transform: uppercase;
	padding-left: var(--padding-left-30);
}

.event-block_three-date::before {
	position: absolute;
	content: "\f16c";
	left: 0px;
	top: 0px;
	font-family: "Flaticon";
	color: var(--color-three);
}

.event-block_three-day {
	position: relative;
	font-weight: 600;
	letter-spacing: 1px;
	font-size: var(--font-18);
	text-transform: uppercase;
	color: var(--color-three);
	margin-left: var(--margin-left-30);
}

.event-block_three-heading {
	position: relative;
	font-weight: 700;
	margin-top: var(--margin-top-10);
}

.event-block_three-heading a {
	position: relative;
	color: var(--color-two);
}

.event-block_three-heading a:hover {
	color: var(--color-three);
}

.event-block_three-text {
	position: relative;
	line-height: 28px;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-top: var(--margin-top-10);
}

.event-block_three-place {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-top: var(--margin-top-15);
}

.event-block_three-place strong {
	position: relative;
	color: var(--color-two);
}

.speaker-schedule {
	position: relative;
	padding-bottom: var(--padding-bottom-110);
}

.speaker-schedule_form {
	position: relative;
	padding: 40px 30px;
	background-size: cover;
}

.speaker-schedule_title-box {
	position: relative;
	text-align: center;
	margin-bottom: var(--margin-bottom-25);
}

.speaker-schedule_title {
	position: relative;
	color: var(--white-color);
	font-size: var(--font-18);
}

.speaker-schedule_heading {
	position: relative;
	font-weight: 700;
	color: var(--white-color);
	margin-top: var(--margin-top-10);
}

/*** 

====================================================================
	Event Three
====================================================================

***/

.event-three {
	position: relative;
	padding: 110px 0px 110px;
}

.event-three .leadership-one_block {
	margin-bottom: var(--margin-bottom-30);
}

/*** 

====================================================================
	Event Detail
====================================================================

***/

.event-detail {
	position: relative;
	padding: 110px 0px 80px;
}

.event-detail .upper-box {
	position: relative;
	margin-bottom: var(--margin-bottom-35);
}

.event-detail .inner-container {
	position: relative;
	max-width: 970px;
	margin: 0 auto;
}

.event-detail_image {
	position: relative;
	margin-bottom: 40px;
}

.event-detail_author {
	position: relative;
	padding-top: 15px;
	padding-left: 180px;
}

.event-detail_author-image {
	position: absolute;
	left: 0px;
	top: 0px;
	overflow: hidden;
	border: 10px solid var(--color-four);
	border-radius: 100px 100px 0px 100px;
	max-width: 160px;
}

.event-detail_author-name {
	position: relative;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-18);
	text-transform: uppercase;
}

.event-detail_author-name span {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-14);
	text-transform: capitalize;
}

.event-detail_author-location {
	position: relative;
	line-height: 24px;
	color: var(--color-five);
	font-size: var(--font-14);
	margin-top: var(--margin-top-15);
}

.event-detail_author-location span {
	position: relative;
	font-weight: 700;
	color: var(--color-three);
}

.event-detail_author-timing {
	position: relative;
	margin-left: -16px;
	top: 1px;
	color: var(--color-five);
	font-size: var(--font-14);
	padding: 10px 25px 10px 35px;
	border-radius: 0px 50px 50px 0px;
	margin-top: var(--margin-top-15);
	background-color: var(--color-ten);
}

.event-detail_author-icon {
	position: absolute;
	left: 153px;
	bottom: 0px;
	width: 40px;
	height: 41px;
	line-height: 40px;
	text-align: center;
	color: var(--color-two);
	font-size: var(--font-18);
	font-family: "Flaticon";
	border-radius: 50px 50px 50px 0px;
	background-color: var(--main-color);
}

.event-detail p {
	color: var(--color-five);
	margin-bottom: var(--margin-bottom-30);
}

.event-detail_subtitle {
	margin-bottom: var(--margin-bottom-25);
}

.event-detail_image-two {
	position: relative;
}

.event-detail_image-two img {
	position: relative;
	width: 100%;
	display: block;
}

.event-detail_title{
	max-width: 380px;
}

.event-detail_list {
	position: relative;
}

.event-detail_list li {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-16);
	padding-left: var(--padding-left-30);
	margin-bottom: var(--margin-bottom-15);
}

.event-detail_list li::before {
	position: absolute;
	content: "\f1a9";
	left: 0px;
	font-family: "Flaticon";
	font-size: var(--font-16);
}

.event-detail .middle-column {
	position: relative;
	margin-top: var(--margin-top-30);
	margin-bottom: var(--margin-bottom-30);
}

/*** 

====================================================================
	Register Section
====================================================================

***/

.register-section {
	position: relative;
	padding: 110px 0px 80px;
}

.register-section .row {
	margin: 0px -40px;
}

.register-section .column {
	padding: 0px 40px;
}

.register-section h4 {
	font-weight: 500;
	color: var(--black-color);
	margin-bottom: var(--margin-bottom-30);
}

.styled-form {
	position: relative;
}

.styled-form .submit-text {
	font-weight: 400;
	font-size: var(--font-15);
	color: var(--main-color-two);
	margin-top: var(--margin-top-10);
}

.styled-form .form-group {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.styled-form .form-group label {
	position: relative;
	display: block;
	font-weight: 500;
	font-size: var(--font-16);
	margin-bottom: var(--margin-bottom-15);
}

.checkout-section .styled-form .form-group {
	position: relative;
	margin-bottom: var(--margin-bottom-20);
}

.styled-form .form-group .check-box {
	line-height: 24px;
	font-size: var(--font-15);
	font-weight: normal;
	padding-top: var(--padding-top-5);
	color: var(--main-color-two);
}

.styled-form .form-group .check-box label {
	position: relative;
	display: block;
	width: 100%;
	line-height: 22px;
	padding: 4px 10px 0px;
	padding-left: 25px;
	font-size: 16px;
	font-weight: 400;
	color: #4e4f51;
	cursor: pointer;
	margin-bottom: 0;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.styled-form .form-group .check-box label a {
	color: var(--color-three);
}

.styled-form .form-group .check-box input[type="checkbox"] {
	display: none;
}

.styled-form .form-group .check-box input[type="checkbox"]:checked+label:before {
	border: 5px solid var(--main-color);
	background: var(--white-color);
}

.styled-form .form-group .check-box input[type="checkbox"]:checked+label:after {
	max-width: 20px;
	opacity: 1;
}

.styled-form .form-group .check-box label:before {
	position: absolute;
	left: 0;
	top: 8px;
	height: 14px;
	width: 12px;
	background-color: var(--white-color);
	content: "";
	border-radius: 0px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
	border: 1px solid rgba(var(--black-color-rgb), 0.15);
}

.styled-form input[type="text"],
.styled-form input[type="email"],
.styled-form input[type="password"],
.styled-form input[type="tel"],
.styled-form input[type="number"],
.styled-form input[type="url"],
.styled-form select,
.styled-form textarea {
	position: relative;
	display: block;
	width: 100%;
	background: var(--white-color);
	line-height: 30px;
	padding: 8px 30px;
	height: 56px;
	font-weight: 400;
	border-radius: 50px;
	font-size: var(--font-14);
	border: 2px solid #f4f4f4;
	color: var(--color-two);
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	font-family: var(--font-family-Inter);
}

.checkout-section .styled-form input[type="text"],
.checkout-section .styled-form input[type="email"],
.checkout-section .styled-form input[type="password"],
.checkout-section .styled-form select {
	line-height: 26px;
	height: 44px;
}

.styled-form select option {
	text-indent: 20px;
}

.styled-form .form-group button {}

.styled-form textarea {
	resize: none;
	height: 110px;
}

.styled-form input:focus,
.styled-form select:focus,
.styled-form textarea:focus {
	border-color: var(--main-color);
}

/*** 

====================================================================
	Sidebar Page Container
====================================================================

***/

.sidebar-page-container {
	position: relative;
	padding: 110px 0px 70px;
}

.sidebar-page-container .content-side,
.sidebar-page-container .sidebar-side {
	margin-bottom: var(--margin-bottom-40);
}

.sidebar-page-container .content-side .news-block_one-image img{
   min-height: 505px;
   object-fit: cover;
}

@media(max-width:767px){
	.sidebar-page-container .content-side .news-block_one-image img{
		min-height: 100%;
	 }
}
.sticky-top {
	top: 90px;
	z-index: 1;
}

/*** 

====================================================================
	Sidebar Widget
====================================================================

***/

.sidebar-widget {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.sidebar-widget .widget-content {
	position: relative;
	padding: 35px 35px;
	background-color: var(--color-twelve);
}

.sidebar-widget_title {
	position: relative;
	color: var(--color-two);
	margin-bottom: var(--margin-bottom-25);
}

/* Search Widget */

.sidebar .search-box .form-group {
	position: relative;
	margin: var(--margin-zero);
}

.sidebar .search-box .form-group input[type="text"],
.sidebar .search-box .form-group input[type="search"] {
	position: relative;
	line-height: 28px;
	font-size: var(--font-14);
	padding: 10px 50px 10px 25px;
	background: none;
	display: block;
	width: 100%;
	height: 56px;
	border-radius: 0px;
	color: var(--color-two);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	border: 1px solid var(--color-ten);
}

.sidebar .search-box .form-group input::placeholder,
.sidebar .search-box .form-group textarea::placeholder {
	color: var(--color-two);
}

.sidebar .search-box .form-group button {
	position: absolute;
	right: 0px;
	top: 0px;
	height: 56px;
	width: 56px;
	display: block;
	cursor: pointer;
	line-height: 56px;
	font-weight: normal;
	border-radius: 0px;
	font-size: var(--font-16);
	color: var(--color-two);
	background: none;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.sidebar .search-box .form-group button:hover {
	background-color: var(--main-color);
	color: var(--white-color);
}

/* Category Widget */

.category-list {
	position: relative;
}

.category-list li {
	position: relative;
	margin-bottom: var(--margin-bottom-20);
}

.category-list li a {
	position: relative;
	display: block;
	color: var(--color-five);
	font-size: var(--font-16);
	padding-left: var(--padding-left-25);
}

.category-list li a::before {
	position: absolute;
	content: '';
	left: 0px;
	top: 7px;
	width: 8px;
	height: 8px;
	border-radius: 50px;
	border: 1px solid var(--color-five);
}

.category-list li:last-child {
	margin-bottom: var(--margin-zero);
}

.category-list li a:hover {
	color: var(--color-three);
}

.category-list li span {
	position: absolute;
	right: 0px;
}

.author-widget .widget-content {
	text-align: center;
	padding-top: var(--padding-zero);
}

.author-widget_image {
	position: relative;
	width: 200px;
	height: 300px;
	margin: 0 auto;
	overflow: hidden;
	border-radius: 0px 0px 100px 100px;
}

.author-widget_name {
	position: relative;
	font-weight: 700;
	letter-spacing: 1px;
	color: var(--color-two);
	font-size: var(--font-20);
	text-transform: uppercase;
	margin-top: var(--margin-top-30);
}

.author-widget_text {
	position: relative;
	line-height: 28px;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-top: var(--margin-top-15);
}

.author-widget_socials {
	position: relative;
	margin-top: var(--margin-top-25);
}

.author-widget_socials li {
	position: relative;
	margin: 0px 4px;
	display: inline-block;
}

.author-widget_socials li a {
	position: relative;
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 50px;
	display: inline-block;
	color: var(--color-two);
	font-size: var(--font-14);
	background-color: var(--white-color);
}

.author-widget_socials li a:hover {
	color: var(--white-color);
	background-color: var(--color-three);
}

/* Post Widget */

.post-widget .post {
	position: relative;
	min-height: 80px;
	padding-left: var(--padding-left-95);
	margin-bottom: var(--margin-bottom-20);
}

.post-widget .post:last-child {
	margin-bottom: var(--margin-zero);
	padding-bottom: var(--padding-zero);
}

.post-widget .post .thumb {
	position: absolute;
	left: 0;
	top: 0;
	height: 80px;
	width: 80px;
	border-radius: 50%;
	overflow: hidden;
}

.post-widget .post .thumb a {
	display: block;
}

.post-widget .post .thumb img {
	display: block;
	width: 100%;
	height: auto;
}

.post-widget .post h6 {
	line-height: 24px;
	margin-top: 3px;
}

.post-widget .post h6 a {
	color: var(--black-color);
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.post-widget .post h6 a:hover {
	color: var(--color-three);
}

.post-widget .post .post-date {
	position: relative;
	display: block;
	font-weight: 500;
	letter-spacing: 1px;
	color: var(--color-three);
	font-size: var(--font-12);
	text-transform: uppercase;
}

/* Popular Tags */

.popular-tags a {
	position: relative;
	padding: 7px 18px;
	font-weight: 600;
	letter-spacing: 1px;
	display: inline-block;
	color: var(--color-two);
	font-size: var(--font-12);
	text-transform: uppercase;
	background-color: var(--white-color);
	margin-right: var(--margin-right-5);
	margin-bottom: var(--margin-bottom-10);
}

.popular-tags a:hover {
	color: var(--white-color);
	background-color: var(--main-color);
}

.sidebar-page-container.left-sidebar .sidebar-side {
	order: 1;
}

.sidebar-page-container.left-sidebar .content-side {
	order: 2;
}

/*** 

====================================================================
	Error Section
====================================================================

***/

.error-section {
	position: relative;
	text-align: center;
	padding: 110px 0px 80px;
}

.error-section .content {
	position: relative;
}

.error-section h1 {
	line-height: 1em;
	font-size: 340px;
	font-weight: 700;
	color: rgba(var(--black-color-rgb), 0.15);
}

.error-section h2 {
	margin-top: var(--margin-top-15);
}

.error-section .text {
	line-height: 1.8em;
	font-size: var(--font-18);
	color: var(--color-two);
	margin-top: var(--margin-top-35);
}

.error-section .button-box {
	margin-top: var(--margin-top-35);
}

.error-section .button-box .theme-btn {
	border-radius: 0px;
	padding: 18px 40px;
}

/*** 

====================================================================
	Blog Detail
====================================================================

***/

.blog-detail {
	position: relative;
}

.blog-detail_inner {
	position: relative;
}

.blog-detail_image {
	position: relative;
}

.blog-detail_image img {
	position: relative;
	width: 100%;
	display: block;
	height: 500px;
	object-fit: cover;
}

.blog-detail_content {
	position: relative;
	padding: 45px 30px 30px;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.blog-detail_date {
	position: absolute;
	left: 30px;
	top: -17px;
	font-weight: 600;
	padding: 6px 15px;
	letter-spacing: 2px;
	color: var(--color-two);
	font-size: var(--font-14);
	text-transform: uppercase;
	background-color: var(--main-color);
}

.blog-detail_meta {
	position: relative;
}

.blog-detail_meta li {
	position: relative;
	display: inline-block;
	color: var(--color-five);
	text-transform: capitalize;
	padding-left: var(--padding-left-25);
	margin-right: var(--margin-right-20);
}

.blog-detail_meta li .icon {
	position: absolute;
	left: 0px;
	top: 4px;
	line-height: 1em;
	color: var(--color-four);
}

.blog-detail_heading {
	margin: 15px 0px 15px;
}

blockquote {
	position: relative;
	padding: 35px 35px;
	font-style: italic;
	line-height: 32px;
	color: var(--color-five);
	font-size: var(--font-18);
	margin-top: var(--margin-top-30);
	background-color: var(--color-seven);
	margin-bottom: var(--margin-bottom-40);
}

.blockquote_author {
	position: relative;
	display: block;
	font-weight: 700;
	font-style: normal;
	text-transform: uppercase;
	color: var(--color-three);
	margin-top: var(--margin-top-10);
}

.blockquote_quote {
	position: absolute;
	right: 30px;
	bottom: 20px;
	opacity: 0.20;
	line-height: 1em;
	font-style: normal;
	font-family: "Flaticon";
	color: var(--color-three);
	font-size: var(--font-50);
}

.blog-detail .two-column {
	position: relative;
	margin-top: var(--margin-top-30);
	margin-bottom: var(--margin-bottom-30);
}

/* Post Share Options */

.post-share-options {
	position: relative;
	margin-top: var(--margin-top-30);
}

.post-share-options .post-share-inner {
	position: relative;
	padding: 25px 0px 0px;
	border-top: 1px solid rgba(var(--black-color-rgb), 0.10);
}

.post-share-options .tags span {
	position: relative;
	font-weight: 700;
	font-size: var(--font-16);
	color: var(--black-color);
	margin-right: var(--margin-right-10);
}

.post-share-options .tags a {
	position: relative;
	font-weight: 400;
	line-height: 1em;
	color: var(--color-two);
	font-size: var(--font-14);
	text-transform: capitalize;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	margin-right: var(--margin-right-10);
}

.post-share-options .tags a:last-child {
	margin-right: 0px;
}

.post-share-options .tags a:hover {
	color: var(--main-color);
}

.post-share-options .social-box {
	position: relative;
}

.post-share-options .social-box span {
	position: relative;
	font-weight: 600;
	font-size: var(--font-16);
	color: var(--black-color);
	margin-right: var(--margin-right-10);
}

.post-share-options .social-box li {
	position: relative;
	display: inline-block;
	margin-right: var(--margin-right-10);
}

.post-share-options .social-box li:last-child {
	margin-right: var(--margin-zero);
}

.post-share-options .social-box li a {
	position: relative;
	font-weight: 400;
	line-height: 1em;
	color: var(--color-two);
	font-size: var(--font-14);
	text-transform: capitalize;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.post-share-options .social-box li a:hover {
	color: var(--main-color);
}

/* Blog Author Box */

.blog-author-box {
	position: relative;
	padding: 30px 30px;
	margin-top: var(--margin-top-45);
	background-color: var(--color-seven);
}

.blog-author-box .author-inner {
	position: relative;
	min-height: 170px;
	padding-right: 200px;
}

.blog-author-box .thumb {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 170px;
	height: 170px;
	border: 4px solid var(--white-color);
}

.blog-author-box .name {
	position: relative;
	font-weight: 700;
	color: var(--color-two);
	font-size: var(--font-24);
}

.blog-author-box .name span {
	position: relative;
	display: block;
	color: var(--color-four);
	font-size: var(--font-14);
	text-transform: capitalize;
	margin-top: var(--margin-top-10);
}

.blog-author-box .text {
	position: relative;
	color: var(--color-five);
	font-size: var(--font-16);
	margin-top: var(--margin-top-15);
}

.blog-author-box .social-box {
	position: absolute;
	right: 200px;
	top: 5px;
}

.blog-author-box .social-box li {
	position: relative;
	display: inline-block;
	margin-right: var(--margin-right-5);
}

.blog-author-box .social-box li a {
	position: relative;
	color: var(--color-two);
	font-size: var(--font-16);
}

.blog-author-box .social-box li a:hover {
	color: var(--color-three);
}

/* Comment Login Box */

.comment-login_box {
	position: relative;
	margin-top: var(--margin-top-40);
}

.comment-login_title {
	position: relative;
	font-weight: 500;
	color: var(--color-two);
	margin-bottom: var(--margin-bottom-20);
}

.comment-login_box-inner {
	position: relative;
	padding: 20px 30px;
	border-radius: 8px;
	margin-bottom: var(--margin-bottom-40);
	border: 2px solid rgba(var(--black-color-rgb), 0.08);
}

.comment-login_box-text {
	position: relative;
	font-size: var(--font-14);
	color: var(--color-two);
}

.comment-login_box-nav {
	position: relative;
}

.comment-login_box-nav li {
	position: relative;
	line-height: 1em;
	display: inline-block;
	margin-right: var(--margin-right-15);
	padding-right: var(--padding-right-15);
	border-right: 2px solid rgba(var(--black-color-rgb), 0.15);
}

.comment-login_box-nav li a {
	position: relative;
	line-height: 1em;
	font-size: var(--font-14);
	color: var(--color-two);
}

.comment-login_box-nav li:last-child {
	border-right: none;
	margin-right: var(--margin-zero);
	padding-right: var(--padding-zero);
}

.comment-login_box-nav li a:hover {
	color: var(--black-color);
}

.comments-area {
	position: relative;
}

.comments-area .group-title {
	position: relative;
	margin-bottom: var(--margin-bottom-25);
}

.comments-content {
	position: relative;
	padding: 45px 40px;
	border-radius: 5px;
	background-color: var(--color-seven);
	margin-bottom: var(--margin-bottom-30);
}

.comment-box {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.comment-box:last-child {
	margin-bottom: var(--margin-zero);
}

.comment-box .comment {
	position: relative;
	padding-left: var(--padding-left-80);
}

.comment-box .author-thumb {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 60px;
	height: 60px;
	overflow: hidden;
	border-radius: 50%;
}

.comment-options {
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 1;
}

.comment-options li {
	position: relative;
	display: inline-block;
	margin-left: var(--margin-left-15);
}

.comment-options li a {
	position: relative;
	font-size: var(--font-14);
	color: var(--color-fiftyeight);
}

.comment-options li a:hover {
	color: var(--black-color);
}

.comment-box .comment-info {
	position: relative;
	padding-top: var(--padding-top-5);
}

.comment-box .comment-info strong {
	position: relative;
	display: block;
	font-size: var(--font-18);
}

.comment-box .comment-time {
	position: relative;
	font-size: var(--font-14);
	margin-top: var(--margin-top-5);
}

.comment-box .text {
	position: relative;
	line-height: 28px;
	font-size: var(--font-14);
	margin-top: var(--margin-top-15);
	margin-bottom: var(--margin-bottom-15);
}

.likes-option {
	position: absolute;
	right: 0px;
	bottom: 0px;
}

.likes-option li {
	position: relative;
	display: inline-block;
	margin-left: var(--margin-left-15);
	padding-left: var(--padding-left-25);
}

.likes-option li span {
	position: absolute;
	left: 0px;
	top: 4px;
	line-height: 1em;
}

.comment-reply {
	position: relative;
	font-size: var(--font-14);
	color: var(--color-fiftyeight);
}

.comment-reply span {
	position: relative;
	margin-right: var(--margin-right-5);
}

.reply-comment {
	position: relative;
	padding: 40px 40px;
	border-radius: 5px;
	margin-left: var(--margin-left-80);
	background-color: var(--white-color);
}

.comment-form {
	position: relative;
	margin-top: var(--margin-top-50);
}

.comment-form .group-title {
	position: relative;
	margin-bottom: var(--margin-bottom-20);
}

.comment-form .default-form {
	position: relative;
	padding: 45px 45px;
	border-radius: 5px;
	background-color: var(--color-seven);
}

.comment-form .default-form input,
.comment-form .default-form textarea {
	background-color: var(--white-color) !important;
}

/*** 

====================================================================
	Comment Form
====================================================================

***/

.comment-form-outer {
	position: relative;
}

.comment-form .form-group {
	margin-bottom: var(--margin-bottom-25);
}

.comment-form .form-group:last-child {
	margin-bottom: 0px;
}

.comment-form .form-group input[type="text"],
.comment-form .form-group input[type="password"],
.comment-form .form-group input[type="tel"],
.comment-form .form-group input[type="email"],
.comment-form .form-group select {
	position: relative;
	display: block;
	width: 100%;
	line-height: 28px;
	padding: 10px 25px;
	height: 60px;
	border-radius: 0px;
	font-size: var(--font-14);
	color: var(--color-fourtynine);
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	background-color: var(--white-color);
	border: 1px solid rgba(var(--black-color-rgb), 0.10);
}

.comment-form .form-group textarea::-webkit-input-placeholder,
.comment-form .form-group input::-webkit-input-placeholder {
	color: var(--color-twentyone);
}

.comment-form .form-group input[type="text"]:focus,
.comment-form .form-group input[type="password"]:focus,
.comment-form .form-group input[type="tel"]:focus,
.comment-form .form-group input[type="email"]:focus,
.comment-form .form-group select:focus,
.comment-form .form-group textarea:focus {
	border-color: var(--black-color);
}

.comment-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 24px;
	padding: 15px 25px 25px;
	font-size: var(--font-14);
	color: var(--color-fourtynine);
	height: 190px;
	resize: none;
	border-radius: 0px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	background-color: var(--white-color);
	border: 1px solid rgba(var(--black-color-rgb), 0.10);
}

/*** 

====================================================================
	Contact Form
====================================================================

***/

.contact-form .form-group {
	margin-bottom: var(--margin-bottom-25);
}

.contact-form .form-group:last-child {
	margin-bottom: 0px;
}

.contact-form .form-group input[type="text"],
.contact-form .form-group input[type="password"],
.contact-form .form-group input[type="tel"],
.contact-form .form-group input[type="email"],
.contact-form .form-group select {
	position: relative;
	display: block;
	width: 100%;
	line-height: 28px;
	padding: 10px 25px;
	height: 56px;
	border-radius: 0px;
	font-size: var(--font-14);
	color: var(--color-fourtynine);
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
	border: 1px solid rgba(var(--black-color-rgb), 0.04);
}

.contact-form .form-group textarea::-webkit-input-placeholder,
.contact-form .form-group input::-webkit-input-placeholder {
	color: var(--color-twentyone);
}

.contact-form .form-group input[type="text"]:focus,
.contact-form .form-group input[type="password"]:focus,
.contact-form .form-group input[type="tel"]:focus,
.contact-form .form-group input[type="email"]:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus {
	border-color: var(--black-color);
}

.contact-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 24px;
	padding: 15px 25px 25px;
	font-size: var(--font-14);
	color: var(--color-fourtynine);
	height: 190px;
	resize: none;
	border-radius: 0px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	background-color: var(--white-color);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
	border: 1px solid rgba(var(--black-color-rgb), 0.04);
}

.contact-form input.error,
.contact-form select.error,
.contact-form textarea.error {
	border-color: #ff0000 !important;
}

.contact-form label.error {
	display: block;
	line-height: 24px;
	padding: 5px 0px 0px;
	margin: 0px;
	text-transform: uppercase;
	font-size: 12px;
	color: #ff0000;
	font-weight: 500;
}

.map-one {
	position: relative;
	padding: 110px 0px 110px;
}

.map-one iframe {
	width: 100%;
	height: 450px;
}


/*** 

====================================================================
	Contact Page Section
====================================================================

***/

.contact-page-section {
	position: relative;
	padding: 100px 0px 70px;
}

.contact-page-section .info-column {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.contact-page-section .info-column .inner-column {
	position: relative;
}

.contact-page-section .info-column .info-box {
	position: relative;
	margin-bottom: var(--margin-bottom-50);
}

.contact-page-section .info-column .info-box .box-inner {
	position: relative;
	padding: 40px 40px;
	border: 1px solid #dddddd;
}

.contact-page-section .info-column .info-box .icon {
	position: relative;
	display: block;
	line-height: 1em;
	font-size: var(--font-50);
	color: var(--main-color);
	font-family: "Flaticon";
	margin-bottom: var(--margin-bottom-10);
}

.contact-page-section .info-column .info-box h6 {
	text-transform: uppercase;
	margin-bottom: var(--margin-bottom-10);
}

.contact-page-section .info-column a {
	position: relative;
	font-size: var(--font-16);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.contact-page-section .info-column a:hover {
	color: var(--main-color);
}

.contact-page-section .info-column .social-box {
	position: relative;
}

.contact-page-section .info-column .social-box li {
	position: relative;
	display: inline-block;
}

.contact-page-section .info-column .social-box li a {
	position: relative;
	font-size: var(--font-16);
	width: 40px;
	height: 40px;
	line-height: 38px;
	text-align: center;
	border-radius: 0px;
	border: 1px solid #dddddd;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.contact-page-section .info-column .social-box li a:hover {
	background-color: var(--main-color);
	border-color: var(--main-color);
	color: var(--white-color)
}

.contact-page-section .form-column {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.contact-page-section .form-column .inner-column {
	position: relative;
}

.contact-page-section .form-column iframe {
	position: relative;
	left: 0;
	top: 0;
	width: 100%;
	height: 415px;
	margin-top: var(--margin-top-30);
}



/*** 

====================================================================
	Contact Page Section
====================================================================

***/

.contact-page-section-two {
	position: relative;
	padding: 100px 0px 0px;
}

.contact-page-section-two .map-column {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.contact-page-section-two .map-column .inner-column {
	position: relative;
}

.contact-page-section-two .map-column iframe {
	position: relative;
	left: 0;
	top: 0;
	width: 100%;
	height: 570px;
}


.contact-page-section-two .info-column {
	position: relative;
	margin-bottom: var(--margin-bottom-30);
}

.contact-page-section-two .info-column .inner-column {
	position: relative;
	margin-left: var(--margin-left-60);
	border-left: 1px solid rgba(var(--black-color-rgb), 0.10);
}

.contact-page-section-two .info {
	position: relative;
}

.contact-page-section-two .info li {
	position: relative;
	line-height: 26px;
	color: var(--color-two);
	font-size: var(--font-16);
	padding-left: var(--padding-left-45);
	margin-bottom: var(--margin-bottom-40);
	padding-bottom: var(--padding-bottom-40);
	border-bottom: 1px solid rgba(var(--black-color-rgb), 0.10);
}

.contact-page-section-two .info li span {
	position: relative;
	display: block;
	font-weight: 600;
	font-size: var(--font-22);
	color: var(--color-two);
	margin-bottom: var(--margin-bottom-10);
}

.contact-page-section-two .info li:last-child {
	border-bottom: none;
}

.contact-form-section {
	position: relative;
	padding-bottom: var(--padding-bottom-110);
}

.contact-form-section h3 {
	position: relative;
	margin-bottom: var(--margin-bottom-25);
}

.xs-sidebar-group .close-button {
	font-family: "Flaticon";
}